<div
  class="row"
  *ngIf="
    label !== 'id' &&
    label !== 'created_at' &&
    label !== 'updated_at' &&
    label !== 'shipping'
  "
  [ngClass]="styleClass"
>
  <div class="col-12">
    <div class="row block-wrapper">
      <div class="col-md-3 block-label col-sm-3">
        <dt *ngIf="label">{{ handleParseLabel(label) }}</dt>
      </div>
      <div class="col-md-9 block-value col-sm-9">
        <a
          *ngIf="link && value"
          [routerLink]="link"
          [queryParams]="this.queryParams"
          >{{ value }}</a
        >
        <dd *ngIf="!link && value">{{ value }}</dd>
        <div class="card" *ngIf="objectKeys(objectResults).length">
          <div class="card-body">
            <div class="col-12">
              <div class="row" *ngFor="let key of objectKeys(objectResults)">
                <div class="col-md-4 col-sm-5">
                  <dt>{{ handleParseLabel(key) }}</dt>
                </div>
                <div class="col-md-8 col-sm-7">
                  <dd>
                    {{ objectResults[key] }}
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="arrayResults.length">
          <div class="card-body">
            <div class="row" *ngFor="let items of arrayResults">
              <div class="col-12">
                <div class="row" *ngFor="let key of objectKeys(items)">
                  <div class="col-md-4 col-sm-5">
                    <dt>{{ handleParseLabel(key) }}</dt>
                  </div>
                  <div class="col-md-8 col-sm-7">
                    <dd>
                      {{ items[key] }}
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { routes as layoutRoutes } from "../../layout/layout.module";

import { AuthGuardService, LoggedInAuthGuardService } from "../services";

import { LayoutComponent } from "src/app/layout/layout.component";
import { AuthComponent } from "src/app/auth/auth.component";
import { NotFoundComponent } from "src/app/layout/error/not-found/not-found.component";

/**
 * Represents Routes & Layout
 * as the main app wrapper
 * @exports routes
 */

export const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    canActivate: [LoggedInAuthGuardService],
  },
  {
    path: "dashboard",
    canActivate: [AuthGuardService],
    // loadChildren: () => import("../../layout/layout.module").then((m) => m.LayoutModule),
    component: LayoutComponent,
    children: [
      ...layoutRoutes,
      {
        path: "",
        data: {},
        loadChildren: () =>
          import("../../layout/error/error.module").then((m) => m.ErrorModule),
      },
    ],
    data: {
      breadcrumb: "MENU.MN000",
    },
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

/**
 * Represents Routing module
 * @export RoutingModule
 * @class RoutingModule
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class RoutingModule {}

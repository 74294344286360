import { BaseValidator } from "src/app/commons/utils/base-validator";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import {
  isNumeric,
  isNumber,
  isString,
  removePad,
  isEmptyVal,
  Utils,
} from "src/app/commons/utils";
import { GtinFormatter } from "../entity";

export class ProductValidator extends BaseValidator {
  static checkNetVolume(c: AbstractControl) {
    let inputVal = c.value;

    if (isEmptyVal(inputVal)) {
      return null;
    }

    if (isString(inputVal) || isNumber(inputVal)) {
      if (isString(inputVal)) {
        inputVal = Utils.toNumber(inputVal.trim());
      }
      if (inputVal > 0 && isNumeric(inputVal)) {
        return null;
      } else {
        return { invalidProduct: "FORM_ERRORS.FE002" };
      }
    }
    return null;
  }

  static maxDeviation(c: AbstractControl) {
    let inputVal = removePad(c.value);
    let form = c.parent as FormGroup;
    let C007 = form.get("C007");

    if (C007 && C007.value) {
      if (isNumeric(inputVal)) {
        if (isString(inputVal)) {
          inputVal = Utils.toNumber(inputVal);
        }
        if (inputVal > 0) {
          return null;
        } else {
          return { invalidProduct: "FORM_ERRORS.FE002" };
        }
      } else {
        return { invalidProduct: "FORM_ERRORS.FE001" };
      }
    }
    return null;
  }

  static required(c: AbstractControl) {
    let inputVal = removePad(c.value);
    if (isEmptyVal(inputVal)) {
      return { invalidProduct: "FORM_ERRORS.FE001" };
    }
    return null;
  }

  static greaterThanZero(c: AbstractControl) {
    let inputVal = c.value;

    if (
      inputVal == null ||
      (typeof inputVal == "string" && inputVal.trim() == "")
    ) {
      return null;
    }

    if (!isNumeric(inputVal)) {
      return { invalidProduct: "FORM_ERRORS.FE003" };
    } else {
      inputVal = Utils.toNumber(inputVal);
    }

    if (inputVal > 0) {
      return null;
    } else {
      return { invalidProduct: "FORM_ERRORS.FE002" };
    }
  }

  static greaterThanEqualToZero(c: AbstractControl) {
    let inputVal = c.value;

    if (
      inputVal == null ||
      (typeof inputVal == "string" && inputVal.trim() == "")
    ) {
      return null;
    }

    if (!isNumeric(inputVal)) {
      return { invalidProduct: "FORM_ERRORS.FE003" };
    } else {
      //parsed to number
      inputVal = Utils.toNumber(inputVal);
    }

    if (inputVal >= 0) {
      return null;
    } else {
      return { invalidProduct: "FORM_ERRORS.FE004" };
    }
  }

  static numeric(c: AbstractControl) {
    let inputVal = c.value;

    if (
      inputVal == null ||
      (typeof inputVal == "string" && inputVal.trim() == "")
    ) {
      return null;
    }

    inputVal = Utils.toNumber(inputVal);
    if (!isNumeric(inputVal)) {
      return { invalidProduct: "FORM_ERRORS.FE003" };
    }
    return null;
  }

  static tempRange(c: AbstractControl) {
    let inputVal = c.value;

    const checkRequired = ProductValidator.required(c);
    if (checkRequired) {
      return checkRequired;
    }

    if (!isNumeric(inputVal)) {
      return { invalidProduct: "FORM_ERRORS.FE003" };
    } else {
      inputVal = Utils.toNumber(inputVal);
    }

    if (inputVal > -30 && inputVal < 30) {
      return null;
    } else {
      return { invalidProduct: "FORM_ERRORS.FE005" };
    }
  }

  static freshnessPromise(c: AbstractControl) {
    const isInvalid = ProductValidator.greaterThanZero(c);
    if (isInvalid) {
      return {
        invalidProduct: "FORM_ERRORS.FE006",
      };
    }

    return null;
  }

  static async isDeliveryCodeExist(
    param: {
      key: string;
      value: number | string;
      id?: string;
    },
    callback: (arg: any) => Promise<any>
  ) {
    try {
      const { result } = await callback({
        filter: [
          {
            key: param.key,
            value: param.value,
          },
        ],
      });
      const products = result || [];

      if (products.length) {
        //It will trigger in [UPDATE] case where id is existed
        if (param.id) {
          let foundProduct = products.find((item) => {
            return item.id == param.id;
          });

          if (foundProduct) {
            if (foundProduct.id == param.id) {
              return null;
            }
          }
        }
        return "FORM_ERRORS.FE007";
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static verifyGtinCode(c: AbstractControl) {
    let inputVal = c.value;

    if (isEmptyVal(inputVal)) {
      return null;
    }

    if (!GtinFormatter.isValid(inputVal)) {
      return {
        invalidProduct: "FORM_ERRORS.FE008",
      };
    }

    return null;
  }
}

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  TemplateRef,
  HostListener,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { SelectionType } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IQueryParams } from "src/app/core/ITypes";

import { ISort } from "../ITypes";

@Component({
  selector: "sv-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
})
export class DatatableComponent implements OnInit, OnDestroy {
  @ViewChild("table", { static: true }) table;
  // defaults
  private unsubscribe$ = new Subject<void>();
  @Input() requestCallback: (...args) => void;
  @Input() rows: [];
  @Input() columns: [];
  @Input() limit: number = 10;
  @Input() count: number = 0;
  @Input() loading: boolean = false;
  @Input() externalPaging: boolean = false;
  @Input() page = (e: any) => e;
  @Input() sort = (e: any) => {
    this.handleSortChange(e);
  };
  @Input() rowDetailTemplate: TemplateRef<any>;
  @Input() chkboxSelection = false;
  @Input() resize = false;
  @Input() offset: number = 0;
  @Input() navigateOnSort: boolean = false;
  @Input() navigateOnFilter: boolean = false;
  @Input() showHorizontalScroll: boolean = false;
  @Input() translate: boolean = false;
  sorting: ISort[] = [];
  cssClasses: any = {
    sortAscending: "datatable-icon-up",
    sortDescending: "datatable-icon-down",
    sortUnset: "datatable-icon-sort-unset",
    pagerLeftArrow: "fa fa-chevron-left",
    pagerRightArrow: "fa fa-chevron-right",
    pagerPrevious: "fa fa-angle-double-left",
    pagerNext: "fa fa-angle-double-right",
  };
  pageLimitOptions = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
    {
      value: 150,
    },
  ];
  SelectionType = SelectionType;
  @Input() requestParams: IQueryParams = {
    limit: 25,
    offset: 0,
    count: 0,
    search: "",
    deliveryMethod: "",
    status: "",
    startDate: "",
    endDate: "",
    direction: "desc",
    order: "O001",
  };
  show = true;
  scrollBarHorizontal = window.innerWidth < 1200;
  debug = false;

  @Output() sortOrder = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {
    // set default sort as per query params
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        const paramsLen = Object.keys(params).length;
        if (!paramsLen) {
          this.sorting = [];
          return;
        }
        if (paramsLen && params.limit) {
          this.limit = params.limit;
        }
        if (paramsLen && params.offset) {
          this.offset = params.offset;
        }
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            const value = params[key];
            if (value === "asc" || value === "desc") {
              this.sorting.push({ prop: key, dir: value });
              /* const isSortKeyExist = this.sorting.find((item) => {
                return item.prop == params.order;
              });
              if (!isSortKeyExist) {
                this.sorting.push({ prop: params.order, dir: value });
              } */
            }
          }
        }
      });

    if (this.route.snapshot.queryParams["mode"] === "debug") {
      this.debug = true;
    }
  }

  sendRequest(params) {
    const {
      limit,
      offset,
      direction,
      order,
      search,
      status,
      startDate,
      endDate,
    } = params;

    if (limit && offset) {
      this.requestParams.offset = limit * offset;
    }
    if (direction) {
      this.requestParams.direction = direction;
    }
    if (order) {
      this.requestParams.order = order;
    }
    if (search) {
      this.requestParams.search = search;
    }
    if (status) {
      this.requestParams.status = status;
    }
    if (startDate) {
      this.requestParams.startDate = startDate;
    }
    if (endDate) {
      this.requestParams.endDate = endDate;
    }
    if (limit) {
      this.requestParams.limit = limit;
    }

    this.requestCallback(this.requestParams);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleOnPageLimitChange(selectedLimit: any) {
    this.limit = selectedLimit;
    this.page({
      limit: selectedLimit,
      offset: 0, //this.offset
      count: this.count,
      direction: this.requestParams.direction,
      search: this.requestParams.search,
    });
  }

  changeRowsLimit(event) {
    this.table.onFooterPage(event);
  }

  handlePageChange = ({ limit, offset }) => {
    const params = this.route.snapshot.queryParams;

    // set query params
    this.router.navigate([], {
      queryParams: { ...params, limit, offset },
    });
  };

  handleSortChange(o) {
    const direction = o.newValue || this.requestParams.direction;
    const order = o.column.prop;
    const query = this.route.snapshot.queryParams;

    if (this.navigateOnSort) {
      this.router.navigate([], {
        queryParams: {
          ...query,
          limit: this.requestParams.limit,
          offset: 0,
          search: this.requestParams.search,
        },
      });
    }

    this.sortOrder.emit({
      value: {
        ...query,
        direction,
        order,
      },
      query,
    });
  }

  handleOnFormEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.sendRequest(this.requestParams);
    }
  }

  handleFilterChange() {
    const {
      order,
      direction,
      status,
      limit,
      offset,
      search,
      startDate,
      endDate,
    } = this.requestParams;

    // set query params
    this.router.navigate([], {
      queryParams: {
        search,
        order,
        direction,
        limit,
        offset,
        status,
        startDate,
        endDate,
      },
    });
  }
}

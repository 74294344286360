export const DEFAULT_PRODUCT_FIELDS = ["P000", "M002", "M001"];
export const DISPLAY_GROUP_FIELD_KEY = "D001";
export const ASSORTMENT_FIELD_KEY = "D002";
export const DELIVERY_GTIN_CODE_KEY = "S004";
export const DELIVERY_GTIN_CODE_KEY2 = "S001";
export const SKU_KEY = "S000";
export const PICTURE_KEY = "pictures";
export const DISPLAY_GROUP_FIELDS = ["D001", "D002"];
export const ERROR_MSG = {
  UNCHECK_SELECTOR_FIELD: {
    title: "Sorry, Cannot Uncheck",
    msg: "Products in the campaign have values associated with this {FIELD_NAME} field. Please ensure all values are cleared before unchecking.",
  },
};

import { ICampaignProductInput } from "../ITypes";
import { IProjectProduct } from "src/app/commons/ITypes";
import { Utils } from "src/app/commons/utils";
import { DISPLAY_GROUP_FIELDS } from "../constants";

export class ProductEditor {
  static fromModel(
    products: ICampaignProductInput[],
    productFields = []
  ): IProjectProduct[] {
    return products.map((item) => {
      const { id, revisedState, details, ...product } = item;

      if (productFields.length > 0) {
        const productProperties = {
          id: id,
          C001: product.C001,
        };

        productFields.forEach((fieldKey) => {
          if (DISPLAY_GROUP_FIELDS.indexOf(fieldKey) != -1) {
            if (!productProperties["D000"]) {
              productProperties["D000"] = [
                {
                  [fieldKey]: Utils.getPropByString(
                    product,
                    `D000.0.${fieldKey}`
                  ),
                },
              ];

              return false;
            }

            productProperties["D000"][0][fieldKey] = Utils.getPropByString(
              product,
              `D000.0.${fieldKey}`
            );

            return false;
          }
          productProperties[fieldKey] = product[fieldKey];
        });

        return {
          ...productProperties,
          P000: Utils.toNumber(item.P000),
          P003: Utils.toNumber(item.P003),
        } as any;
      }

      return {
        ...product,
        P000: Utils.toNumber(item.P000),
        P003: Utils.toNumber(item.P003),
      };
    });
  }

  static toModel(products: IProjectProduct[]) {
    return products.map((item) => {
      return {
        ...item,
        P000: Utils.numberFormat(item.P000, {
          decimal: 2,
        }),
        P003: Utils.numberFormat(item.P003, {
          decimal: 2,
        }),
      };
    });
  }
}

import { ISupplyTimePattern } from "src/app/commons/ITypes";
import { Calendar, hasAllEmptyValues, isEmptyVal } from "src/app/commons/utils";

export class SupplyRule {
  static validatePattern(patterns) {
    const validPatterns = patterns.filter((item) => {
      return !(
        isEmptyVal(item.acceptOrder) ||
        isEmptyVal(item.lastCall) ||
        isEmptyVal(item.supplyTime)
      );
    });

    return {
      length: validPatterns.length,
      valid: validPatterns.length === 0 ? false : true,
    };
  }

  static sortPatterns(patterns = []) {
    return patterns.sort((a, b) => {
      if (a.acceptOrder === null && b.acceptOrder !== null) {
        return -1;
      } else if (a.acceptOrder !== null && b.acceptOrder === null) {
        return 1;
      } else if (a.acceptOrder !== null && b.acceptOrder !== null) {
        if (a.acceptOrder !== b.acceptOrder) {
          return a.acceptOrder - b.acceptOrder;
        }
      }

      if (!a.lastCall && b.lastCall) {
        return -1;
      } else if (a.lastCall && !b.lastCall) {
        return 1;
      } else if (a.lastCall && b.lastCall) {
        const lastCallA = Calendar.parse(
          a.lastCall,
          Calendar.formatType.hour24
        );
        const lastCallB = Calendar.parse(
          b.lastCall,
          Calendar.formatType.hour24
        );

        if (lastCallA.isBefore(lastCallB)) {
          return -1;
        } else if (lastCallA.isAfter(lastCallB)) {
          return 1;
        }
      }

      return 0;
    });
  }

  static filterSortPatterns(patterns: Array<ISupplyTimePattern> = []) {
    const filteredPatterns = patterns.filter((item) => {
      return hasAllEmptyValues(item) ? false : true;
    });

    return this.sortPatterns(filteredPatterns);
  }
}

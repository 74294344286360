import { Pipe, PipeTransform } from "@angular/core";

interface PriceProps {
  currency?: string;
  decimal: number;
}

@Pipe({
  name: "priceFormatter",
  pure: true,
})
export class PriceFormatterPipe implements PipeTransform {
  defaultOpt: PriceProps = {
    decimal: 2,
  };

  transform(amount: number, opt: Partial<PriceProps> = null): string {
    opt = opt || this.defaultOpt;

    if (!amount) {
      if (amount !== 0) {
        return "-";
      } else {
        return amount.toString();
      }
    } else {
      const formattedAmount = Number(amount).toFixed(opt.decimal);
      const formattedPrice = formattedAmount.replace(".", ",");
      return `${formattedPrice}`;
    }
  }
}

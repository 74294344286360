import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { BreadcrumbService } from "angular-crumbs";
import { Utils } from "src/app/commons/utils";
import { BREADCRUMB_OPTION } from "../ITypes";
import { LocaleService } from "src/app/core/services";
import { Observable, Subscription } from "rxjs";

@Injectable()
export class NavigatorService {
  private breadcrumLangSubscription: Subscription;

  constructor(
    private breadcrumService: BreadcrumbService,
    private route: ActivatedRoute,
    private localeService: LocaleService
  ) {}

  changeBreadcrumb(name: string, opt: BREADCRUMB_OPTION = null) {
    const keyName = name;

    if (opt && opt.param) {
      if (opt.translate) {
        name = this.localeService.trans(name);
      }

      name = Utils.stripTextAsArgs(name, opt.param);
    }

    let mode = this.route.snapshot.queryParams["mode"];

    if (mode === "debug") {
      name = `{{${keyName}}} ${name}`;
    }

    if (opt && opt.parent) {
      this.breadcrumService.changeBreadcrumb(this.route.parent.snapshot, name);
      this.route.parent.snapshot.data["param"] = opt.param;
    } else {
      this.breadcrumService.changeBreadcrumb(this.route.snapshot, name);
      this.route.parent.snapshot.data["param"] = opt.param;
    }
  }

  subcribeLocaleBreadcrum(name: string, opt: BREADCRUMB_OPTION = null) {
    if (this.breadcrumLangSubscription) {
      this.breadcrumLangSubscription.unsubscribe();
    }

    this.changeBreadcrumb(name, opt);

    this.breadcrumLangSubscription = this.localeService
      .onTranslationChange()
      .subscribe((res) => {
        this.changeBreadcrumb(name, opt);
      });
  }

  unsubcribe() {
    if (this.breadcrumLangSubscription) {
      this.breadcrumLangSubscription.unsubscribe();
    }
  }
}

export const profileFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa01",
          ref: "account",
          type: "panel",
          required: false,
          label: "Personal Information",
          components: [
            {
              id: null,
              key: "firstName",
              type: "textfield",
              required: false,
              label: "First Name",
              placeholder: "First Name",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "lastName",
              type: "textfield",
              required: false,
              label: "Last Name",
              placeholder: "Last Name",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "email",
              type: "textfield",
              required: false,
              label: "Email (user name)",
              placeholder: "Email",
              default: null,
              validate: true,
              validators: [{ callback: "required" }, { callback: "email" }],
            },
          ],
        },
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa02",
          ref: "account-password",
          type: "panel",
          required: false,
          label: "Account Password",
          components: [
            {
              id: null,
              key: "password",
              type: "password",
              required: false,
              label: "Password",
              placeholder: "Password",
              default: null,
              validate: true,
              validators: [
                {
                  callback: "minLength",
                  value: 8,
                  msg: "Password must have atleast {value} characters",
                },
                {
                  callback: "strongPassword",
                },
              ],
            },
            {
              id: null,
              key: "cpassword",
              type: "password",
              required: false,
              tansit: false,
              label: "Confirm Password",
              placeholder: "Confirm Password",
              default: null,
              validate: true,
              validators: [
                { callback: "minLength" },
                { callback: "mustMatch", requiredIf: "'{password}' != ''" },
              ],
            },
          ],
        },
      ],
    },
  },
};

import { Component, Input, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "sv-info-block",
  templateUrl: "./info-block.component.html",
  styleUrls: ["./info-block.component.scss"],
})
export class InfoBlockComponent implements OnInit {
  // defaults
  @Input() label: string = null;
  @Input() value: any = null;
  @Input() link: string;
  @Input() queryParams: Object = {};
  @Input() styleClass: any;
  objectKeys = Object.keys;
  arrayResults = [];
  objectResults = {};

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    this.value = this.handleParseValue(this.value);
  }

  handleParseLabel(label: string) {
    try {
      if (!label) {
        return "";
      }
      return label
        .replace(new RegExp("_", "g"), " ")
        .replace(/([A-Z])/g, " $1");
    } catch (error) {
      this.toastr.error(error.message, "Error Occurred");
    }
  }

  handleParseValue(value: any) {
    try {
      if (!value) {
        return "-";
      }
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          this.arrayResults = value;
        } else {
          this.objectResults = value;
        }
      } else {
        return value;
      }
    } catch (error) {
      this.toastr.error(error.message, "Error Occurred");
    }
  }
}

import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, from, of, throwError } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  share,
  switchMap,
  take,
  tap,
} from "rxjs/operators";

import { ToastrService } from "ngx-toastr";

import { ApiService } from "./api.service";
import { SiteConfigService } from "./site-config.service";
import { JwtService } from "./jwt.service";
import { CacheService } from "./cache.service";
import { LocaleService } from "./locale.service";
import { APP_CONFIG } from "src/app/config/app.config";
import { FirebaseService } from "src/app/core/lib/firebase";

import {
  IAuthUserSession,
  HttpRespone,
  IAuthorizeUserResponse,
  IUserRole,
  UserRole,
  GrantRoleType,
  IAuthResponse,
  IAuthSessionStorage,
  IAuthUser,
  UserProductRole,
} from "src/app/core/ITypes";

import { Utils } from "src/app/commons/utils";
import { AppConstant } from "src/app/commons/app.constant";
import { ChannelType } from "src/app/config";
import { IdleService } from "./idle.service";

@Injectable()
export class AuthService {
  // defaults
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private authorizedUser$: Observable<any>;
  private userSubject = new BehaviorSubject<IAuthUserSession>(
    {} as IAuthUserSession
  );
  private refreshTimer: any;

  authUser$ = this.userSubject.asObservable().pipe(distinctUntilChanged());
  authUser: IAuthUserSession;
  logoutTimer;
  refreshTokenInterval = 0; //milliseconds

  // private loggedInUser: BehaviorSubject<IUser> = new BehaviorSubject(null);

  /**
   * Creates an instance of AuthService.
   * @param {CacheService} cacheService
   * @param {ApiService} api
   * @memberof AuthService
   */
  constructor(
    private cacheService: CacheService,
    private siteConfigService: SiteConfigService,
    private jwtService: JwtService,
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private route: Router,
    @Inject(APP_CONFIG) private config,
    private toastrService: ToastrService,
    private firebaseService: FirebaseService,
    private localeService: LocaleService,
    private idleService: IdleService
  ) {
    this.refreshTokenInterval = this.config.tokenRenewalInterval * 60 * 1000;
    this.authStateListener();

    this.initializeRefreshTokenScheduler();
    this.watchIdleTimeout();
  }

  getUserSubject() {
    return this.userSubject;
  }

  login(payload): Observable<IAuthUserSession> {
    const body = new HttpParams({ fromObject: payload });
    const authHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http
      .post(`/auth/token/`, body.toString(), { headers: authHeaders })
      .pipe(
        take(1),
        tap(async (result: IAuthResponse) => {
          const accessToken = result.access_token;
          this.jwtService.setAccessToken(accessToken);
        }),
        switchMap((result: IAuthResponse) => {
          const accessToken = result.access_token;

          return this.populateAuthUser().pipe(
            tap(async (authUserSession) => {
              const { token } = await this.createCustomTokenByChannel(
                authUserSession.channelId
              );
              this.setAuth(token, {
                channelId: authUserSession.store.channelId,
                role: authUserSession.store.role,
              });
              this.localeService.setDefaultLang();
              //this.syncLogoutTimer();
            })
          );
        }),
        catchError((err: any) => {
          console.error(err);
          return throwError({
            message: err.error.detail || "Incorrect email or password",
          });
        })
      );
  }

  async createCustomTokenByChannel(channelId: number) {
    return this.api
      .getRaw<{ custom_token: string; token_type: string }>(
        `/auth/custom_token?channel_id=${channelId}`
      )
      .toPromise<{ custom_token: string; token_type: string }>()
      .then((res) => {
        return this.firebaseService.signInWithCustomToken(res.custom_token);
      });
  }

  invokeChannelToken(channelId: number) {
    return this.api.getRaw<{ custom_token: string; token_type: string }>(
      `/auth/custom_token?channel_id=${channelId}`
    );
  }

  authenticateUser(
    _forceSessionRefreshAtStart = false
  ): Observable<IAuthUserSession> | Observable<never> {
    if (this.authorizedUser$) {
      return this.authorizedUser$;
    } else {
      this.authorizedUser$ = (<Observable<IAuthorizeUserResponse>>(
        this.api.get(`/user?limit=1`)
      )).pipe(
        mergeMap(async (response: IAuthorizeUserResponse) => {
          const storedChannelID = this.jwtService.session?.channelId;
          const loggedInStore = response.meta.user.roles.find((item) => {
            if (
              storedChannelID &&
              storedChannelID == response.meta.user.channelId
            ) {
              return item.channelId == storedChannelID;
            } else {
              return item.channelId == response.meta.user.channelId;
            }
          });
          const selectedStore = loggedInStore || response.meta.user.roles[0];

          if (!loggedInStore) {
            return throwError({
              status: HttpRespone.UNAUTHENTICATED,
              message: "Sorry, you do not have access to any store.",
            });
          }

          if (storedChannelID) {
            const storedUserRole = this.jwtService.session?.role;

            if (storedUserRole != loggedInStore.role) {
              this.refreshToken({ role: loggedInStore.role });
            }
          }

          const authUser: Partial<IAuthUserSession> = {
            ...response.meta.user,
            name: `${response.meta.user.firstName} ${response.meta.user.lastName}`.trim(),
            store: selectedStore, //.find((item) => item.channelId == 3)
            channelProductRole: null,
          };

          await this.siteConfigService.load(selectedStore.channelId);
          authUser.channelProductRole = this.getProductRole(
            selectedStore.channelId
          );

          return of(authUser);
        }),
        switchMap((res) => {
          return res;
        }),
        share(),
        catchError((err: any) => {
          console.error(err);
          if (
            err.status == HttpRespone.UNAUTHORIZED ||
            err.status == HttpRespone.UNAUTHENTICATED
          ) {
            this.logout();
          }
          return throwError(err);
        })
      );
      return this.authorizedUser$;
    }
  }

  authenticateFirebaseUser() {
    return this.firebaseService.getAuthUser();
  }

  authStateListener() {
    this.firebaseService.onAuthStateListener(
      (user) => {
        if (!user) {
          if (this.jwtService.session) {
            this.jwtService.destroyToken();
            this.clearLocalSession();
            this.route.navigateByUrl("/");
          }
        }
      },
      (_error) => {}
    );

    this.firebaseService.onTokenStateListener(async (user) => {
      if (user) {
        //const token = await user.getIdToken();
      }
    });
  }

  /**
   * represents logout
   * @private
   * @memberof AuthService
   */
  async logout() {
    try {
      /* this.cacheService.remove("user");
      this.cacheService.remove("uc"); */
      this.userSubject.next(null);
      this.jwtService.destroyToken();
      this.clearLogoutTimer();
      this.clearTimerWatcher();
      this.localeService.reset();
      this.router.navigateByUrl("/");
      this.authUser = null;
      this.idleService.removeIdleSession();
      await this.firebaseService.signOut();
    } catch (error) {
      throw error;
    }
  }

  async clearLocalSession() {
    this.userSubject.next(null);
    this.clearLogoutTimer();
    this.clearTimerWatcher();
    this.localeService.reset();
    this.authUser = null;
    this.idleService.removeIdleSession();
  }

  get isLoggedIn() {
    const user = this.cacheService.get("user");
    if (user) {
      this.loggedIn.next(true);
      return true;
    }
    this.loggedIn.next(false);
    return false;
  }

  get assignedStores(): IUserRole[] {
    const userAssignedStores = this.authUser.roles || [];

    return Utils.getUniqueListBy(userAssignedStores, "name") as IUserRole[];
  }

  get assignedChannels(): IUserRole[] {
    const userAssignedStores = this.authUser.roles || [];

    return Utils.getUniqueListBy(
      userAssignedStores,
      "channelId"
    ) as IUserRole[];
  }

  get assignedChannelsOptions() {
    return this.assignedChannels.map((item) => {
      return {
        label: item.label,
        value: item.channelId,
      };
    });
  }

  get adminPrivilegedStores() {
    return this.assignedStores.filter((item) => item.role == UserRole.ADMIN);
  }

  get nonAdminPrivilegedStores() {
    return this.assignedStores.filter((item) => item.role == UserRole.USER);
  }

  getAvailableRolesByStoreName(storename: string) {
    const selectedUserStores = this.assignedStores.filter((assignedStore) => {
      return assignedStore.name == storename;
    });

    const isSelectedStoreHasAdminRole = selectedUserStores.find(
      (item) => item.role == UserRole.ADMIN
    );

    return AppConstant.userRoles.filter((item) => {
      if (isSelectedStoreHasAdminRole) {
        return true;
      } else {
        return item.value == UserRole.USER;
      }
    });
  }

  set setCredentials(credentials: string) {
    this.cacheService.set("uc", credentials);
  }

  get getCredentials() {
    return this.cacheService.get("uc");
  }

  setAuth(access_token: string, userRole: Partial<IUserRole>) {
    this.jwtService.setAccessToken(access_token);

    this.jwtService.saveToken(access_token, {
      channelId: userRole.channelId,
      role: userRole.role,
      token_time: this.authUser.session.token_time,
      token_refresh_time: this.authUser.session.token_refresh_time,
      refreshLock: 0,
    });

    this.scheduleRefreshToken();
    this.idleService.initialize();
  }

  async setIdentity(identity: IAuthUserSession) {
    const authToken = this.jwtService.getToken();
    const storeUrl = identity.store[this.config.environment];
    const authSession = this.jwtService.getLocalStorage();

    const authUser = {
      ...identity,
      session: {
        access_token: authToken,
        environment: this.config.environment,
        token_expiry: this.jwtService.getExpiryDate(),
        token_refresh_time:
          authSession?.token_refresh_time || Date.now().toString(),
        token_time: authSession?.token_time || Date.now().toString(),
      },
      storeUrl: storeUrl,
    };

    this.userSubject.next(authUser);
    this.authUser = authUser;

    if (this.jwtService.getLocalStorage()) {
      //this.syncLogoutTimer();
    }
  }

  async patchUserDetails(identity: Partial<IAuthUser>) {
    const authUser = <IAuthUserSession>{
      ...this.userSubject.value,
      ...identity,
    };

    if (identity.firstName || identity.lastName) {
      authUser.name = `${authUser.firstName} ${authUser.lastName}`.trim();
    }

    this.userSubject.next(authUser);
    this.authUser = authUser;
  }

  patchUserSession(identity: Partial<IAuthUserSession["session"]>) {
    const authUserSession = <IAuthUserSession>{
      ...this.userSubject.value,
      session: {
        ...this.userSubject.value.session,
        identity,
      },
    };

    this.userSubject.next(authUserSession);
    this.authUser = authUserSession;
  }

  populateAuthUser(): Observable<IAuthUserSession> {
    const authToken = this.jwtService.getToken();

    if (authToken) {
      return (this.authenticateUser() as Observable<IAuthUserSession>).pipe(
        map((res: IAuthUserSession) => {
          this.setIdentity(res);
          return res;
        }),
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
    } else {
      return of(null);
    }
  }

  get getAPIKey() {
    const user = this.cacheService.get("user");
    if (!user) {
      return "";
    }
    return user.password;
  }

  /**
   * represents get
   * refresh token
   * @memberof AuthService
   */
  getRefreshToken = () => {
    try {
      return new Observable((observer) => {
        // this.api
        //   .getResponseHeaders("/user/refresh")
        //   .subscribe(({ status_code, headers }) => {
        //     if (status_code === 204 && headers) {
        //       const token = headers.get("Authorization");
        //       this.cacheService.set("token", token);
        //       observer.next(headers.get("Authorization"));
        //     }
        //   });
        this.route.navigate(["/"]);
        observer.next(true);
      });
    } catch (error) {
      console.log("error in getRefreshToken: ", error.message);
    }
  };

  get bearerToken() {
    const token = this.jwtService.getToken();
    return `Bearer ${token}`;
  }

  syncLogoutTimer() {
    const expiryTime = this.jwtService.getRemainingSessionExpiryTime();
    if (!this.logoutTimer) {
      this.logoutTimer = setTimeout(() => {
        this.logout();
        //this.sessionExpiryAlert();
      }, expiryTime);
    }
  }

  clearLogoutTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
  }

  clearTimerWatcher() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  sessionExpiryAlert(msg) {
    if (!msg) return;

    this.toastrService.clear();

    this.toastrService.error(
      msg || "Your session has been expired. Please login again.",
      ""
    );
  }

  refreshToken(sessionStorageChanges: Partial<IAuthSessionStorage>) {
    this.jwtService.setRefreshToken(sessionStorageChanges);
  }

  validateRolePermission(roles: GrantRoleType[]) {
    return Promise.resolve(this.hasRolePermission(roles));
  }

  validateChannelPermission(channelIDs: number[]) {
    return Promise.resolve(this.hasChannelPermission(channelIDs));
  }

  hasRolePermission(roles: GrantRoleType[]) {
    const foundRole = roles.find((role) => {
      return UserRole[role] === this.authUser.store.role;
    });

    return foundRole ? true : false;
  }

  hasChannelPermission(channelIDs: number[]) {
    const foundChannel = channelIDs.find((channelID) => {
      return channelID === this.authUser.store.channelId;
    });

    return foundChannel ? true : false;
  }

  getProductRole(channelID): UserProductRole {
    const productRole =
      this.siteConfigService.getItem("product.accessLevel") ||
      this.config.defaultProductRole;

    return productRole;
  }

  initializeRefreshTokenScheduler() {
    if (!this.jwtService.getToken()) {
      return;
    }

    /* if(this.isTokenRefreshTimeElapsed()){
      return;
    } */

    this.scheduleRefreshToken();
  }

  scheduleRefreshToken() {
    const timeSinceLastRefresh = this.getRefreshTokenTimeElapsed();
    const timeUntilNextRefresh = Math.max(
      this.refreshTokenInterval - timeSinceLastRefresh,
      0
    );

    this.refreshTimer = setTimeout(async () => {
      if (!this.jwtService.getToken()) {
        return;
      }
      // Schedule the next token refresh
      await this.renewAccessToken({ shouldRunTimer: true });
    }, timeUntilNextRefresh);
  }

  async renewAccessToken(param: { shouldRunTimer: boolean } = null) {
    const session = this.jwtService.session;
    const channelID = this.jwtService.session?.channelId;
    const role = this.jwtService.session?.role;

    if (!session) {
      return;
    }

    if (session && session.refreshLock === 0) {
      const tokenRefreshTime = Date.now().toString();
      this.jwtService.patchTokenDetails({ refreshLock: 1 });

      const idToken = await this.firebaseService.refreshToken();

      this.refreshToken({
        token: idToken,
        channelId: channelID,
        role: role,
        token_refresh_time: tokenRefreshTime,
        refreshLock: 0,
      });

      this.patchUserSession({
        token_refresh_time: tokenRefreshTime,
      });
    } else {
      this.refreshToken({
        token: session.token,
        channelId: channelID,
        role: role,
        token_refresh_time: session.token_refresh_time,
        refreshLock: 0,
      });

      this.patchUserSession({
        token_refresh_time: session.token_refresh_time,
      });
    }

    if (param?.shouldRunTimer) {
      this.scheduleRefreshToken();
    }
  }

  reinvokeTokenRequest(): Observable<any> {
    return from(this.renewAccessToken());
  }

  getRefreshTokenTimeElapsed() {
    const lastRefreshTime = parseInt(
      this.jwtService.getLastRefreshTokenTime() || "0",
      10
    );
    const durationSinceLastRefresh = Date.now() - lastRefreshTime;

    return durationSinceLastRefresh;
  }

  isTokenRefreshTimeElapsed() {
    const timeSinceLastRefresh = this.getRefreshTokenTimeElapsed();

    return timeSinceLastRefresh >= this.refreshTokenInterval;
  }

  verifyRefreshToken() {
    const lastRefreshTime = parseInt(
      this.jwtService.getLastRefreshTokenTime() || "0",
      10
    );
    const durationSinceLastRefresh = Date.now() - lastRefreshTime;

    return durationSinceLastRefresh <= this.refreshTokenInterval;
  }

  watchIdleTimeout() {
    this.idleService.onIdleTimeout().subscribe(() => {
      console.log("IdleSession Timeout.........!!!!!");
      this.logout();
    });
  }
}

export const supplierFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        // Details
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee622",
          ref: "supplierHeader",
          type: "panel",
          required: false,
          label: "Supplier Header",
          labelKey: "SUPPLIER.S000",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              id: null,
              key: "supplier_id",
              type: "number",
              dataType: "intText",
              required: false,
              label: "Supplier Id",
              labelKey: "SUPPLIER.S001",
              unique: false,
              default: null,
              tooltip: '"name"',
              description: "supplier_id, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "title",
              type: "textfield",
              required: false,
              label: "Title",
              labelKey: "SUPPLIER.S002",
              unique: false,
              default: null,
              tooltip: "",
              description: "title, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "full_name",
              type: "textfield",
              required: false,
              label: "Full Name",
              labelKey: "SUPPLIER.S003",
              unique: false,
              default: null,
              tooltip: "",
              description: "full_name, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [],
            },
            {
              id: null,
              key: "reference_code",
              type: "textfield",
              required: false,
              label: "Reference Code",
              labelKey: "SUPPLIER.S004",
              unique: false,
              default: null,
              tooltip: '"weight"',
              description: "reference_code, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
          ],
        },
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee624",
          ref: "supplierHeader",
          type: "panel",
          required: false,
          label: "Address",
          labelKey: "SUPPLIER.S005",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              id: null,
              key: "receive_deliveries",
              type: "bool",
              required: false,
              label: "Receives Deliveries",
              labelKey: "SUPPLIER.S006",
              default: false,
            },
            {
              key: "company_name",
              type: "textfield",
              label: "Company Name",
              labelKey: "SUPPLIER.S007",
            },
            {
              key: "address",
              type: "textfield",
              label: "Address",
              labelKey: "SUPPLIER.S005",
              isLabelRequired: false,
              validators: [
                {
                  callback: "required",
                  requiredIf: "{receive_deliveries} == true",
                },
              ],
            },
            {
              key: "postal_code",
              type: "textfield",
              label: "Postal Code",
              labelKey: "SUPPLIER.S009",
            },
            {
              key: "city",
              type: "textfield",
              label: "City",
              labelKey: "SUPPLIER.S010",
            },
            {
              key: "state",
              type: "textfield",
              label: "State",
              labelKey: "SUPPLIER.S011",
            },
            {
              key: "country",
              type: "textfield",
              label: "Country",
              labelKey: "SUPPLIER.S012",
            },
            {
              key: "latitude",
              type: "textfield",
              label: "Latitude",
              labelKey: "SUPPLIER.S013",
            },
            {
              key: "longitude",
              type: "textfield",
              label: "Longitude",
              labelKey: "SUPPLIER.S014",
            },
            {
              key: "contact_first_name",
              type: "textfield",
              label: "Contact First Name",
              labelKey: "SUPPLIER.S015",
            },
            {
              key: "contact_last_name",
              type: "textfield",
              label: "Contact Last Name",
              labelKey: "SUPPLIER.S016",
            },
            {
              key: "phone",
              type: "textfield",
              label: "Phone Number",
              labelKey: "SUPPLIER.S017",
            },
            {
              key: "fax",
              type: "textfield",
              label: "Fax Number",
              labelKey: "SUPPLIER.S018",
            },
            {
              key: "email",
              type: "textfield",
              label: "Email",
              labelKey: "SUPPLIER.S019",
            },
            {
              key: "notes",
              type: "textarea",
              label: "Notes",
              labelKey: "SUPPLIER.S020",
            },
            {
              key: "supplyPatternActive",
              type: "bool",
              label: "Active",
              labelKey: "SUPPLIER.S021",
              default: false,
              hidden: true,
            },
          ],
        },
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee623",
          ref: "supplierPattern",
          type: "panel",
          required: false,
          label: "Supply Pattern",
          labelKey: "SUPPLIER.S022",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              id: null,
              type: "form-array",
              key: "patterns",
              components: [
                {
                  key: "acceptOrder",
                  type: "dropdown",
                  label: "Accepts Orders",
                  labelKey: "SUPPLIER.S023",
                  containerClass: "col-lg-3 col-md-3",
                  default: null,
                  placeholder: "Select",
                  placeholderKey: "SUPPLIER.S042",
                  data: {
                    values: [
                      {
                        label: "Week 1 / Monday",
                        labelKey: "SUPPLIER.S024",
                        value: 1,
                      },
                      {
                        label: "Week 1 / Tuesday",
                        labelKey: "SUPPLIER.S025",
                        value: 2,
                      },
                      {
                        label: "Week 1 / Wednesday",
                        labelKey: "SUPPLIER.S026",
                        value: 3,
                      },
                      {
                        label: "Week 1 / Thursday",
                        labelKey: "SUPPLIER.S027",
                        value: 4,
                      },
                      {
                        label: "Week 1 / Friday",
                        labelKey: "SUPPLIER.S028",
                        value: 5,
                      },
                      {
                        label: "Week 1 / Saturday",
                        labelKey: "SUPPLIER.S029",
                        value: 6,
                      },
                      {
                        label: "Week 1 / Sunday",
                        labelKey: "SUPPLIER.S030",
                        value: 7,
                      },
                      {
                        label: "Week 2 / Monday",
                        labelKey: "SUPPLIER.S031",
                        value: 8,
                      },
                      {
                        label: "Week 2 / Tuesday",
                        labelKey: "SUPPLIER.S032",
                        value: 9,
                      },
                      {
                        label: "Week 2 / Wednesday",
                        labelKey: "SUPPLIER.S033",
                        value: 10,
                      },
                      {
                        label: "Week 2 / Thursday",
                        labelKey: "SUPPLIER.S034",
                        value: 11,
                      },
                      {
                        label: "Week 2 / Friday",
                        labelKey: "SUPPLIER.S035",
                        value: 12,
                      },
                      {
                        label: "Week 2 / Saturday",
                        labelKey: "SUPPLIER.S036",
                        value: 13,
                      },
                      {
                        label: "Week 2 / Sunday",
                        labelKey: "SUPPLIER.S037",
                        value: 14,
                      },
                    ],
                  },
                  clearable: false,
                },
                {
                  key: "lastCall",
                  type: "time-picker",
                  required: false,
                  label: "Last Call",
                  labelKey: "SUPPLIER.S038",
                  containerClass: "col-lg-2 col-md-3",
                  validate: true,
                  validators: [{ callback: "lastCallTime" }],
                },
                {
                  key: "supplyLeadDays",
                  type: "number",
                  label: "Lead Time (in days)",
                  labelKey: "SUPPLIER.S039",
                  containerClass: "col-lg-2 col-md-3",
                  validators: [{ callback: "supplyLeadDays" }],
                  watchValidity: ["supplyTime", "lastCall"],
                },
                {
                  key: "supplyDay",
                  type: "textfield",
                  label: "Supply Day",
                  labelKey: "SUPPLIER.S040",
                  protected: true,
                  containerClass: "col-lg-2 col-md-3",
                },
                {
                  key: "supplyTime",
                  type: "time-picker",
                  label: "Supply Time",
                  labelKey: "SUPPLIER.S041",
                  containerClass: "col-lg-2 col-md-3",
                  validators: [{ callback: "supplyTime" }],
                },
              ],
            },
          ],
        },
      ],
    },
  },
};

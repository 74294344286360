import { Injectable } from "@angular/core";

import { GeneralService } from "./general.service";
import {
  ApiService,
  HttpQueryService,
  LocaleService,
} from "src/app/core/services";
import { IQueryParams } from "src/app/core/ITypes";
import { ISupplier } from "../ITypes";
import { isNumeric } from "src/app/commons/utils";
import { supplierFormSchema } from "src/app/layout/supplier/write/schema";

@Injectable()
export class SupplierService {
  constructor(
    private api: ApiService,
    private generalService: GeneralService,
    private httpQueryService: HttpQueryService,
    private localeService: LocaleService
  ) {}

  async getFormSchema() {
    try {
      const { status, result } = supplierFormSchema;

      if (status === 200 && result && result.properties) {
        const panels: Array<any> = result.properties.components;
        return panels;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSuppliers(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const skip = queryParams.offset;
      const searchTerm = queryParams.search?.trim();
      const search = Object.assign(
        {},
        isNumeric(searchTerm)
          ? { supplier_id: searchTerm }
          : { title: searchTerm }
      );
      const direction = queryParams.direction;
      const order = queryParams.order;
      const filter = queryParams.filter;

      const querySet = { limit, skip, direction, order, search };

      if (filter) {
        querySet["filter"] = filter;
      }

      if (order === "supplyPatternActive") {
        querySet["order"] = ["supplyPatternActive", "patterns", "supplier_id"];
      }

      const queryString = this.httpQueryService.serialize(querySet, {
        mappedKeys: { full_name: "fullName" },
      });

      /* const queryString = Object.keys(querySet)
        .filter((key) => querySet[key] != null)
        .map((key) => key + "=" + querySet[key])
        .join("&"); */

      let apiEndpoint = `/supplier?${queryString}`;

      /* if (search) {
        apiEndpoint += `${queryString ? "&" : "?"}search=title=${search}`;
      } */

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        const r = result.map((r) => {
          const props = r.properties;

          if (props.patternWeek1 || props.patternWeek2) {
            const patterns = [props.patternWeek1, props.patternWeek2];
            props.pattern_formatted = patterns
              .filter((pattern) => !!pattern)
              .join(" | ");
          } else {
            props.pattern_formatted = `<span class="">No active pattern</span>`;
          }

          if (props.supplyPattern) {
            if (props.supplyPattern.next && props.supplyPattern.next.date) {
              props.resupply = this.generalService.parseDateFormat(
                `${props.supplyPattern.next.date}${props.supplyPattern.next.time}`,
                "LLL",
                "YYYY-MM-DDHH:mm:ss"
              );
            } else {
              props.resupply = "-";
            }
          }

          props.supplyLeadDays_formatted = props.supplyLeadDays || "-";

          props.supplyTime_formatted =
            (props.supplyTime &&
              this.generalService.parseDateFormat(
                props.supplyTime,
                "hh:mm A",
                "HH:mm:ss"
              )) ||
            "-";

          return { ...props, id: r.id };
        });

        return { result: r, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  async getSupplier(id: string) {
    try {
      const { status, result } = await this.api
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const props = result.properties;
        if (props.supplyPattern) {
          if (props.supplyPattern.next && props.supplyPattern.next.date) {
            props.resupply = this.generalService.parseDateFormat(
              `${props.supplyPattern.next.date}${props.supplyPattern.next.time}`,
              "YYYY-MM-DD HH:mm:ss",
              "YYYY-MM-DDHH:mm:ss"
            );
          }
        }
        return props;
      }
    } catch (error) {
      throw error;
    }
  }

  async postFormData(data: ISupplier) {
    try {
      const payload = {
        properties: data,
      };
      const { status, result } = await this.api
        .post(payload, "/supplier")
        .toPromise();

      if (status === 200 && result) {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  async updateFormData(id: string, data: ISupplier) {
    try {
      const payload = {
        properties: data,
        id,
      };
      const { status, result } = await this.api
        .patch(payload, `/supplier`)
        .toPromise();

      if (status === 200 && result) {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  async isSupplierExist(supplier: {
    key: string;
    value: number | string;
    id?: string;
  }): Promise<{ status: boolean; msg: string }> {
    try {
      const params = {
        limit: 50,
        filter: [{ key: supplier.key, value: supplier.value }],
      };

      const { result } = await this.getSuppliers(params);
      const suppliers = result || [];
      let status = result.length > 0;
      let msgLiteral = this.localeService.trans("ERRORS.ER004");

      if (status) {
        let foundSupplier = suppliers.find((item) => {
          return item[supplier.key] == supplier.value;
        });

        //It will trigger in [UPDATE] case where id is existed
        if (foundSupplier && supplier.id) {
          if (foundSupplier.id == supplier.id) {
            return { status: false, msg: "" };
          }
        }

        let validateMsg = {
          supplier_id: { value: foundSupplier, label: "ID" },
          reference_code: { value: foundSupplier, label: "Code" },
        };

        const selectedMsg = validateMsg[supplier.key];

        if (selectedMsg.value) {
          msgLiteral = msgLiteral.replace(
            "{msg}",
            `${selectedMsg.label} : ${selectedMsg.value[supplier.key]}`
          );
        }
      }

      return { status, msg: status ? msgLiteral : "" };
    } catch (e) {
      return { status: false, msg: "" };
    }
  }

  getSchemaField(schema, fieldKey) {
    let foundField;

    schema.every((panel) => {
      const matchedField = panel.components.find((cmp) => cmp.key == fieldKey);
      if (matchedField) {
        foundField = matchedField;
        return false;
      }
      return true;
    });

    if (foundField) {
      return foundField;
    }
  }
}

import { Injectable } from "@angular/core";

import { ApiService, HttpQueryService } from "src/app/core/services";
import { GeneralService } from "./general.service";
import { IQueryParams } from "src/app/core/ITypes";
import { Utils } from "src/app/commons/utils";
import { CustomSearchQueryService } from "src/app/core/services/custom-search-query.service";

interface IWallet {
  wallet_total: string;
  wallet_reimburse: string;
  wallet_credit: string;
}
@Injectable()
export class CustomerService {
  constructor(
    private api: ApiService,
    private generalService: GeneralService,
    private httpQueryService: HttpQueryService,
    private customSearchQueryService: CustomSearchQueryService
  ) {}

  async getCustomers(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      // const search =
      //   (queryParams.search && `email=${queryParams.search}`) || null;
      const search = queryParams.search?.trim();
      const direction = queryParams.direction;
      const order = queryParams.order;

      const mappedQueryParams = {
        fullname: "firstname",
        wallet_balance: "wallet.Q800",
      };

      const queryString = this.httpQueryService.serialize(
        {
          limit,
          skip: offset,
          direction,
          order,
          customSearch: {
            keys: ["phone", "email", "firstname", "lastname"],
            input: search,
          },
        },
        { mappedKeys: mappedQueryParams }
      );

      let apiEndpoint = `/customer?${queryString}`;

      let response = (await this.api.get(apiEndpoint).toPromise()) as any;

      if (Utils.countWords(search) > 1) {
        response = this.customSearchQueryService.find(response, search, {
          keys: ["phone", "email"],
          fullMatch: [["firstname", "lastname"]],
        });
      }

      let { status, result, meta } = response;

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;

          if (props) {
            // Customer Name
            props.fullname = props.firstname + " " + props.lastname;

            // Registration Date
            if (props.created_at) {
              props.created_at = this.generalService.parseDateFormat(
                props.created_at,
                "DD.MM.YYYY"
              );
            }

            props.wallet_details = this.parseWalletInfo(props);
          }

          data.push({
            ...props,
            id: res.id,
          });
        }
        return { result: data, meta };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCustomer(id: string) {
    try {
      const { status, result } = await this.api
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const customer = { customer_id: result.id, ...result.properties };
        customer.full_address = this.parseCustomerAddress(customer);
        customer.wallet_details = this.parseWalletInfo(customer);
        return customer;
      }
    } catch (error) {
      throw error;
    }
  }

  parseCustomerAddress(customer) {
    let fullAddress = "";
    if (customer.street) {
      fullAddress += `${customer.street}, `;
    }
    if (customer.city) {
      fullAddress += `${customer.city}, `;
    }
    if (customer.zip) {
      fullAddress += `${customer.zip}, `;
    }
    if (customer.country) {
      fullAddress += `${customer.country}, `;
    }
    fullAddress = fullAddress.replace(/[, ]+/g, " ").trim();
    return fullAddress;
  }

  parseWalletInfo(customer): IWallet {
    const walletFields = {
      Q800: "wallet_total",
      Q801: "wallet_reimburse",
      Q802: "wallet_credit",
    };
    const state = {};

    Object.keys(walletFields).forEach((propName) => {
      if (customer.wallet) {
        state[walletFields[propName]] =
          ((customer.wallet[propName] &&
            this.generalService.parseAmount(customer.wallet[propName])) ||
            "0.00") + "€";
      } else {
        state[walletFields[propName]] = "0.00" + "€";
      }
    });

    return state as IWallet;
  }

  async getLatestOrdersByCustomer(userUID: string) {
    try {
      let queryParams = this.httpQueryService.serialize({
        limit: 5,
        skip: 0,
        direction: "desc",
        order: "default.O001",
        filter: [{ key: "user_uuid", value: userUID }],
      });

      let apiEndpoint = `/order?${queryParams}`;

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          const selected = res.properties;
          let props = { ...selected.default, amount: selected.amount };

          // Receiver Name
          if (props.O030) {
            props.O030 = props.O030 + " " + props.O031;
          }

          // Delivery Method
          if (props.O010 && props.O011) {
            props.O010 = `${props.O010} (${props.O011})`;
          }

          // Amount
          if (props.amount) {
            props.amount = parseFloat(props.amount);
            props.amount = this.generalService.parseAmount(props.amount);
          }

          // Payment Method
          props.O093 = props.O093
            ? this.generalService.handleStrToArrayParse(props.O093)
            : "-";

          //Order Delivery Date & Time
          props.O003 = props.O003
            ? this.generalService.formatDateTimeToUTC(props.O003)
            : "-";

          //Order Status
          if (props.O900) {
            props.tracking_status = props.O900.label || props.O007;
          } else {
            props.tracking_status = props.O007;
          }

          data.push({
            id: res.id,
            ...props,
          });
        }
        return { result: data, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  async getByUserUID(email: string) {
    try {
      const queryString = this.httpQueryService.serialize({
        filter: [{ key: "user_uuid", value: email }],
        limit: 1,
        skip: 0,
      });
      const { status, result } = await this.api
        .get(`/customer?${queryString}`)
        .toPromise();

      if (status === 200 && result) {
        if (result.length) {
          const firstResult = result[0];
          const customer = {
            customer_id: firstResult.id,
            ...firstResult.properties,
          };

          customer.full_address = this.parseCustomerAddress(customer);
          customer.wallet_details = this.parseWalletInfo(customer);
          return customer;
        }
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AngularFireModule } from "@angular/fire";

import { FirebaseService } from "./firebase.service";

import { FIREBASE_CONFIG } from "./firebase.config";

@NgModule({
  declarations: [],
  imports: [CommonModule, AngularFireModule.initializeApp(FIREBASE_CONFIG)],
  providers: [FirebaseService],
})
export class FirebaseModule {}

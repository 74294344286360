export const environment = {
  production: false,
  baseUrl: "https://staging-core.lafka.tools",
  urlSuffix: "/api/latest",
  xToken: "$1$7sCYP6Qz$1./BBR.GLEJYRLSoNRNPc1",
  firebase: {
    apiKey: "AIzaSyAhxmhpauzFltMvcO2W2Ja7ycjiHXip6OM",
    authDomain: "lafka-staging-backoffice.firebaseapp.com",
    projectId: "lafka-staging-backoffice",
    storageBucket: "lafka-staging-backoffice.appspot.com",
    messagingSenderId: "1050245860856",
    appId: "1:1050245860856:web:f82904fae583bf10b99a0d",
    measurementId: "G-22DSCDRMV4",
  },
};

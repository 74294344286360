import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "sv-spinner-loader",
  templateUrl: "./spinner-loader.component.html",
  styleUrls: ["./spinner-loader.component.scss"],
})
export class SpinnerLoaderComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() fullScreenBackdrop = false;
  @Input() isOverlay = false;

  constructor() {}

  ngOnInit(): void {}
}

import { VENDOR_TYPE } from "src/app/commons/ITypes";

export class VendorType {
  static readonly REGULAR = VENDOR_TYPE.REGULAR;
  static readonly PREMIUM = VENDOR_TYPE.PREMIUM;

  static get values() {
    return [
      {
        label: "Regular",
        value: VENDOR_TYPE.REGULAR,
      },
      {
        label: "Premium",
        value: VENDOR_TYPE.PREMIUM,
      },
    ];
  }

  static findTitle(val: VENDOR_TYPE): string {
    const foundType = this.values.find((item) => item.value == val);

    if (!foundType) {
      return;
    }

    return foundType.label;
  }
}

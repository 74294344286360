<div
  class="d-flex justify-content-center m-5"
  *ngIf="loading"
  [ngClass]="fullScreenBackdrop ? 'fullscreen' : ''"
  [class.overlay-progress]="isOverlay"
>
  <div class="spinner-border text-danger" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

import { _deepClone } from "src/app/commons/utils/helper";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { finalize, map, shareReplay } from "rxjs/operators";

import { IQuery } from "../ITypes";
import { IAuthUserSession } from "./../ITypes";
import { Observable } from "rxjs";

interface IResult {
  from: number;
  to: number;
  per_page: string;
  current_page: number;
  first_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  data?: Array<Object>;
  items?: Array<Object>;
  summary?: Object;
  properties?: any;
}

interface IMeta {
  skip: number;
  limit: number;
  total: number;
  user: IAuthUserSession;
}

interface IResponse {
  charset: string;
  status: number;
  result: any;
  _response?: any;
  meta: IMeta;
}

interface IGenResponse {
  charset: string;
  status: number;
  result: any;
}

@Injectable()
export class ApiService {
  cacheRequest: Map<String, Observable<any>> = new Map();
  /**
   * Creates an instance of ApiService.
   * @param {HttpClient} _http
   * @memberof ApiService
   */
  constructor(private _http: HttpClient) {}

  /**
   * this is to get
   * requested data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  get(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .get(_url, { observe: "body", params })
      .pipe(
        map(({ status, result, meta }: IResponse) => ({ status, result, meta }))
      );
  }

  getRaw<T>(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http.get<T>(_url, { observe: "body", params });
  }

  getByCached(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);

    const httpHeaders = new HttpHeaders({
      cached: "true",
    });

    let observable;

    if (this.cacheRequest.get(this.getCacheReqKey(_url))) {
      observable = this.cacheRequest.get(this.getCacheReqKey(_url));
    } else {
      observable = this._http
        .get(_url, {
          observe: "body",
          params,
          headers: httpHeaders,
        })
        .pipe(shareReplay());
      this.cacheRequest.set(this.getCacheReqKey(_url), observable);
    }

    return observable.pipe(
      map(({ status, meta, _response, result }: IResponse) => {
        _response = _response || result;

        return {
          status,
          result: _deepClone(_response),
          meta,
        };
      })
    );
  }

  /**
   * this is to get
   * request headers
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  getResponseHeaders(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .get(_url, { observe: "response", params })
      .pipe(map((res) => ({ status: res.status, headers: res.headers })));
  }

  /**
   * this is to get a record
   * by its id
   * @param {string} url
   * @param {string} id
   * @returns
   * @memberof ApiService
   */
  getById(url: string, id: string, query?: IQuery) {
    let uri = url ? `${url}/${id}` : `${id}`;
    let { _url, params } = this.generateUrl(uri, query);
    return this._http.get(_url, { observe: "body", params }).pipe(
      map(({ status, result }: IGenResponse) => ({
        status,
        result,
      }))
    );
  }

  /**
   * this is to request create data
   * @param {Object} data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  post(data: any, url: string, query?: IQuery, headers?: HttpHeaders) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .post(_url, data, { observe: "body", params, headers })
      .pipe(
        map(({ status, result }: IGenResponse) => ({
          status,
          result,
        }))
      );
  }

  /**
   * this is to request update data
   * @param {Object} data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  put(data: Object, url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http.put(_url, data, { observe: "body", params }).pipe(
      map(({ status, result }: IGenResponse) => ({
        status,
        result,
      }))
    );
  }

  patch(data: Object, url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http.patch(_url, data, { observe: "body", params }).pipe(
      map(({ status, result }: IGenResponse) => ({
        status,
        result,
      }))
    );
  }

  /**
   * this is to request delete data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  delete(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http.delete(_url, { observe: "body", params }).pipe(
      map(({ status, result }: IGenResponse) => ({
        status,
        result,
      }))
    );
  }

  basicAuth(url: string, headers: HttpHeaders) {
    let { _url } = this.generateUrl(url);
    return this._http.get(_url, { observe: "body", headers }).pipe(
      map((res) => ({
        status: 200,
        result: res,
      }))
    );
  }

  /**
   * this is to generate Api url
   * with params
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  generateUrl(url: string, query?: IQuery) {
    let _url: string = url;
    let params: HttpParams;
    let result = { _url, params };
    if (query) {
      params = new HttpParams();
      Object.keys(query).forEach((key) => {
        params = params.append(key, query[key]);
      });
      result.params = params;
    }
    return result;
  }

  private getCacheReqKey(url: string) {
    return JSON.stringify({
      url,
    });
  }
}

<div
  *ngIf="visible"
  style="z-index: 1040;"
  [ngClass]="{ 'overlay nsm-overlay-open': visible }"
>
  <div
    class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open"
    ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
    style="z-index: 1041;"
  >
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            {{ heading }}
          </h5>
          <button
            aria-label="Close"
            (click)="closeModal()"
            class="nsm-dialog-btn-close c-modal__close js-toggle-modal close"
            data-target="#sv-authRegister-modal"
            type="button"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="c-modal__body">
          <ng-content select="[body]"></ng-content>
        </div>
        <div class="c-modal__footer">
          <ng-content select="[footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

import { FormControl } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

import { Utils, renderFn } from "src/app/commons/utils";

@Component({
  selector: "sv-form-error",
  templateUrl: "./form-error.component.html",
  styleUrls: ["./form-error.component.scss"],
})
export class FormErrorComponent implements OnInit {
  _control: FormControl = null;
  _validators = [];
  _formBindValue: any;

  @Input() key;
  @Input()
  set formBindValue(val) {
    this._formBindValue = val;

    if (val) {
      //this.setValidations();
    }
  }
  get formBindValue() {
    return this._formBindValue;
  }

  @Input() errorMsg = {};

  constructor() {}

  @Input("control")
  set control(val: any) {
    if (val && val.control) {
      this._control = val.control;
    }
  }
  get control() {
    return this._control as FormControl;
  }

  @Input("validators")
  set validators(val) {
    if (val) {
      this._validators = val;
      //this.setValidations();
    }
  }
  get validators() {
    return this._validators;
  }

  ngOnInit(): void {}

  setValidations() {
    if (
      Array.isArray(this.validators) &&
      this.validators.length &&
      this.control &&
      this.formBindValue
    ) {
      const choosenValidators = this.validators
        .filter((validator) => validator.requiredIf)
        .map((validator) => {
          const parseCondition = Utils.stripTextAsArgs(
            validator.requiredIf,
            this.formBindValue
          );

          return this.conditionalValidator(() => {
            return renderFn(parseCondition);
          }, validator.callback);
        });

      const validatorsWithoutConditional = this.validators
        .filter((validator) => !validator.requiredIf)
        .map((item) => item.callback);

      if (validatorsWithoutConditional.length) {
        choosenValidators.push(...validatorsWithoutConditional);
      }

      if (choosenValidators.length) {
        this._control.setValidators(choosenValidators);
        setTimeout(() => this._control.updateValueAndValidity());
      }
    }
  }

  conditionalValidator(predicate, validator) {
    return (formControl) => {
      const dependenField = predicate();
      if (dependenField) {
        return validator(formControl);
      }
      return null;
    };
  }
}

import { Injectable } from "@angular/core";
import { DlDateAdapterMoment } from "angular-bootstrap-datetimepicker";
import * as moment from "moment";

moment.locale("en", {
  week: {
    dow: 1,
  },
});

@Injectable()
export class MomentAdapter extends DlDateAdapterMoment {
  fromMilliseconds(milliseconds: number): moment.Moment {
    return moment(milliseconds);
  }

  /**
   * Returns a moment in time value as milliseconds (local time zone).
   * @param value
   *  a moment or `null`.
   * @returns
   *  a `moment.valueOf()` result for the specified `moment` or `null`
   */
  toMilliseconds(value: moment.Moment | null): number | null {
    return value ? value.valueOf() : undefined;
  }
}

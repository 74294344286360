<nav id="sidebar" class="active" [ngClass]="{ active: !isSidebarActive }">
  <h4>
    <a class="toggle" (click)="toggleSidebar()"
      ><span
        [ngClass]="{
          'fas fa-bars': !isSidebarActive,
          'fa fa-times': isSidebarActive
        }"
      ></span
    ></a>
  </h4>
  <ul class="list-unstyled components mb-5">
    <ng-template ngFor let-route [ngForOf]="routes | async" let-i="index">
      <ng-container *svAccessPermission="route.accessBy">
        <li
          *ngIf="route.visible"
          [ngClass]="{ active: currentRoute === route.url }"
        >
          <a
            class="menu-link"
            [routerLink]="route.url"
            *svIfViewportSize="'gtSmall'"
          >
            <span [class]="route.icon"></span>
            <sv-locale-text
              [key]="route.labelKey"
              [extractKey]="true"
            ></sv-locale-text>
          </a>
          <a
            class="menu-link"
            [routerLink]="route.url"
            (click)="onClickMenuRoute()"
            *svIfViewportSize="'small'"
          >
            <span [class]="route.icon"></span>
            <sv-locale-text
              [key]="route.labelKey"
              [extractKey]="true"
            ></sv-locale-text>
          </a>
        </li>
      </ng-container>
    </ng-template>
  </ul>

  <div class="footer">
    <p>
      © Storeverse 2020
      <!-- <i class="icon-heart" aria-hidden="true"></i> by -->
      <!-- <a href="https://storeverse.io/" target="_blank">Storeverse</a> -->
    </p>
  </div>
</nav>

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { TranslateLoader } from "@ngx-translate/core";

export class TranslationLoader implements TranslateLoader {
  prefix = "/assets/i18n/";
  suffix = ".json";

  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<any> {
    return this.http
      .get(`${this.prefix}${lang}${this.suffix}?v=${new Date().getTime()}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error loading translation file for ${lang}:`, error);

          return of({});
        })
      );
  }
}

import { Utils } from "src/app/commons/utils";
import { IProductInputField, IProjectProductItem } from "../ITypes";
import { DisplayGroupInputField } from "./DisplayGroupInputField";
import { DISPLAY_GROUP_FIELDS } from "../constants";

export class ProductInputField implements IProductInputField {
  id: number;

  constructor(productInput) {
    Object.assign(this, productInput);
  }

  static create(productInputChanges, enabledFields: string[] = []) {
    const entity = {
      id: productInputChanges.id,
      C001: productInputChanges.C001,
    };

    enabledFields.forEach((fieldKey) => {
      if (["D001", "D002"].indexOf(fieldKey) !== -1) {
        if (!entity["D000"]) {
          entity["D000"] = [
            {
              [fieldKey]: Utils.getPropByString(
                productInputChanges,
                `D000.0.${fieldKey}`
              ),
            },
          ];

          return false;
        }

        entity["D000"][0][fieldKey] = Utils.getPropByString(
          productInputChanges,
          `D000.0.${fieldKey}`
        );

        return false;
      }

      entity[fieldKey] = productInputChanges[fieldKey];
    });

    return new ProductInputField(entity);
  }

  static fromAssignedProduct(
    product: IProjectProductItem,
    enabledFields: string[] = []
  ) {
    const entity = {
      ...product.revisedState,
      id: product.id,
      C001: product.C001,
    };

    return this.create(entity, enabledFields);
  }

  static fromSearchedProduct(
    product: IProjectProductItem,
    enabledFields: string[] = []
  ) {
    const entity = {
      ...product.revisedState,
      id: product.id,
      C001: product.C001,
    };

    return this.create(entity, enabledFields);
  }

  static filterOutEmptyProducts(
    items: ProductInputField[],
    enabledFields: string[] = []
  ) {
    return items.filter((item) => {
      return !item.arePropertiesEmpty(enabledFields) ? true : false;
    });
  }

  arePropertiesEmpty(enabledFields: string[] = []) {
    for (let key of enabledFields) {
      let fieldData;
      let mappedKey = key;

      if (["D001", "D002"].indexOf(key) !== -1) {
        fieldData = this["D000"][0][key];
        mappedKey = "D000";
      } else {
        fieldData = this[key];
      }

      if (
        this.hasOwnProperty(mappedKey) &&
        fieldData !== null &&
        fieldData !== undefined &&
        fieldData !== ""
      ) {
        return false;
      }
    }
    return true;
  }

  static areEmpty(product, enabledFields: string[] = []) {
    for (let key of enabledFields) {
      let fieldData;
      let mappedKey = key;

      if (DISPLAY_GROUP_FIELDS.indexOf(key) !== -1) {
        fieldData = product["D000"][0][key];
        mappedKey = "D000";
      } else {
        fieldData = product[key];
      }

      if (
        product.hasOwnProperty(mappedKey) &&
        fieldData !== null &&
        fieldData !== undefined &&
        fieldData !== ""
      ) {
        return false;
      }
    }
    return true;
  }

  comparePropertyChanges() {}
}

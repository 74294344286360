<div class="input-group">
  <input
    class="form-control"
    type="text"
    [placeholder]="placeholder"
    [attr.name]="name"
    [(ngModel)]="value"
    mask="Hh:m0:s0"
    [leadZeroDateTime]="true"
    (blur)="onSelectTime()"
    [ngClass]="inputClass"
  />
  <div class="input-group-append">
    <span
      class="input-group-text"
      id="basic-addon2"
      (click)="openPicker(p1)"
      [ngbPopover]="popContent"
      #p1="ngbPopover"
      placement="bottom"
      triggers="manual"
      [autoClose]="'outside'"
    >
      <i class="fas fa-clock"></i>
    </span>
  </div>
</div>

<ng-template #popContent let-greeting="greeting">
  <ngb-timepicker [(ngModel)]="value" [seconds]="true"></ngb-timepicker>
</ng-template>
<!-- <ng-template #popTitle let-language="language">My Title</ng-template> -->

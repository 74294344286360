import { Injectable } from "@angular/core";

import * as moment from "moment";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ApiService } from "src/app/core/services";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  _sidebarEmitter: Subject<any> = new BehaviorSubject<any>(false);
  sidebarSubscriber$: Observable<any> = this._sidebarEmitter.asObservable();

  constructor(private api: ApiService) {}

  async getStatistics(type: string) {
    try {
      if (!type) {
        return [];
      }
      const today = moment().format("YYYY-MM-DD");
      const startDate = moment().subtract(10, "days").format("YYYY-MM-DD");

      const { status, result, meta } = await this.api
        .get(
          `/statistics?filter=type=${type}&between=date=${startDate},${today}&sort=date:asc`
        )
        .toPromise();

      if (status === 200 && result) {
        return result
          .map((r) => r.properties)
          .sort((a, b) => moment(a.date).unix() - moment(b.date).unix());
      }
    } catch (error) {
      throw error;
    }
  }

  toggleMobileSidebar() {
    this._sidebarEmitter.next(true);
  }
}

<div
  class="text-danger"
  *ngIf="control && control.errors && (control.dirty || control.touched)"
>
  <div *ngIf="control.errors.required">
    <small *ngIf="errorMsg['required']">{{ errorMsg["required"] }}</small>
    <small *ngIf="!errorMsg['required']">This field is required</small>
  </div>
  <div *ngIf="control.errors.unique">
    <small>{{ control.errors.unique }}</small>
  </div>
  <div *ngIf="control.errors.email">
    <small>{{ control.errors.email }}</small>
  </div>
  <div *ngIf="control.errors.invalidProduct">
    <small>{{ control.errors.invalidProduct }}</small>
  </div>
</div>

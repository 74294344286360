import { IConfirmDialog } from "./confirm-dialog.model";
import { ConfirmDialogService } from "./confirm-dialog.service";
import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "sv-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  @ViewChild("confirmationTemplate") public template: TemplateRef<any>;

  confirmSubscription;
  options: IConfirmDialog;

  constructor(
    private confirmDailogService: ConfirmDialogService,
    private modalService: NgbModal
  ) {
    this.confirmSubscription = this.confirmDailogService.confirmSubscriber$.subscribe(
      (response: any) => {
        if (response === true) {
          this.prompt();
          this.confirmDailogService._confirmEmitter.next(false);
        }
      },
      (err: any) => {}
    );
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.confirmDailogService.cancelCallback();
    this.modalService.dismissAll();
  }

  onConfirm(): void {
    this.confirmDailogService.confirmCallback();
    this.modalService.dismissAll();
  }

  prompt() {
    this.options = this.confirmDailogService.options;
    this.modalService.open(this.template, {
      size: "sm",
      centered: true,
      backdrop: "static",
      backdropClass: "dialog-modal-backdrop",
      windowClass: "dialog-modal-container modal-holder",

      // backdropClass: 'light-blue-backdrop'
    });
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }
}

<div class="role-switcher-container">
  <ng-container *ngIf="stores.length > 1">
    <sv-dropdown
      class="dropdown-selectbox"
      [(ngModel)]="selectedStore"
      [items]="stores"
      bindLabel="label"
      bindValue="value"
      [clearable]="false"
      [searchable]="false"
      [hideSelected]="true"
      (change)="changeRole()"
    >
    </sv-dropdown>
  </ng-container>
  <ng-container *ngIf="stores.length == 1">
    <span>{{ (authUser$ | async)?.store.label }}</span>
  </ng-container>
</div>

<sv-spinner-loader
  [loading]="loading"
  [fullScreenBackdrop]="true"
></sv-spinner-loader>

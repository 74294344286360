import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Optional,
  Renderer2,
  /* Optional,
  Self, */
  SimpleChanges,
} from "@angular/core";
import { NgControl, NgModel } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Utils } from "../utils";
// import { NgControl } from "@angular/forms";
@Directive({
  selector: "[svNumberInput]",
})
export class NumberInputDirective implements OnChanges {
  private hasDecimalPoint = false;
  private hasNegativeSign = false;
  private navigationKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Escape",
    "Enter",
    "Home",
    "End",
    "ArrowLeft",
    "ArrowRight",
    "Clear",
    "Copy",
    "Paste",
  ];
  @Input() decimal = false;
  /* private _decimal = false;
  @Input()
  set decimal(val) {
    this._decimal = val;
    console.log("valvalval", val);
    if (val) {
      //this.setDecimalPattern();
    }
  }
  get decimal() {
    return this._decimal;
  }

  private _decimalPlace = 2;
  @Input()
  set decimalPlace(val) {
    this._decimalPlace = val;
  }
  get decimalPlace() {
    return this._decimalPlace;
  } */

  @Input() decimalSeparator: String | String[] = ".";
  @Input() allowNegatives = false;
  @Input() allowPaste = true;
  @Input() negativeSign = "-";
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() pattern?: string | RegExp;
  @Input() keepOnlyNumber = true;
  @Input() castToNumber = true;
  @Input() keepAlwaysDecimal = false;

  private regex: RegExp | null = null;
  inputElement: HTMLInputElement;

  constructor(
    public el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngModel: NgModel,
    @Optional() private ngControl: NgControl
  ) {
    this.inputElement = el.nativeElement;

    if (this.ngModel && this.ngModel.control) {
      // this.ngModel.control.valueChanges
      //   .pipe(
      //     debounceTime(300),
      //     distinctUntilChanged()
      //     //filter((term) => term && term.trim().length > 0)
      //   )
      //   .subscribe((value) => {
      //     this.ngModel.control.setValue(Utils.toNumber(value));
      //   });
    } else if (this.ngControl && this.ngControl.control) {
      // this.ngControl.control.valueChanges
      //   .pipe(
      //     debounceTime(300),
      //     distinctUntilChanged()
      //     //filter((term) => term && term.trim().length > 0)
      //   )
      //   .subscribe((value) => {
      //     this.ngControl.control.setValue(Utils.toNumber(value));
      //   });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pattern"]) {
      this.regex = this.pattern ? RegExp(this.pattern) : null;
    }

    if (changes["min"]) {
      const maybeMin = Number(this.min);
      this.min = isNaN(maybeMin) ? -Infinity : maybeMin;
    }

    if (changes["max"]) {
      const maybeMax = Number(this.max);
      this.max = isNaN(maybeMax) ? Infinity : maybeMax;
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(e: KeyboardEvent): any {
    if (this.keepOnlyNumber) {
      const newValue = this.keptNumberOnly(this.inputElement.value || "");
      if (newValue) {
        this.renderer.setProperty(this.el.nativeElement, "value", newValue);
        this.renderer.setAttribute(this.el.nativeElement, "value", newValue);
        /* this.ngModel.update.emit(newValue);

        if (this.ngControl) {
          this.ngControl.control.setValue(newValue);
        } */
      }
    }
  }

  @HostListener("beforeinput", ["$event"])
  onBeforeInput(e: InputEvent): any {
    if (isNaN(Number(e.data))) {
      if (Array.isArray(this.decimalSeparator)) {
        const extractedSeparator = this.extractSeparator(e.data);
        if (
          extractedSeparator ||
          (e.data === this.negativeSign && this.allowNegatives)
        ) {
          return; // go on
        }
      } else {
        if (
          e.data === this.decimalSeparator ||
          (e.data === this.negativeSign && this.allowNegatives)
        ) {
          return; // go on
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === "a" || e.code === "KeyA") && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === "c" || e.code === "KeyC") && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === "v" || e.code === "KeyV") && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === "x" || e.code === "KeyX") && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === "a" || e.code === "KeyA") && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === "c" || e.code === "KeyC") && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === "v" || e.code === "KeyV") && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === "x" || e.code === "KeyX") && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    let newValue = "";

    if (this.decimal && this.hasSeparator(e.key)) {
      newValue = this.forecastValue(e.key);
      if (Array.isArray(this.decimalSeparator)) {
        const foundSeparator = this.decimalSeparator.filter((sep: string) => {
          return newValue.indexOf(sep) > -1;
        });
        if (
          (foundSeparator.length == 1 &&
            newValue.split(foundSeparator[0] as string).length > 2) ||
          foundSeparator.length > 1
        ) {
          // has two or more decimal points
          e.preventDefault();
        } else {
          this.hasDecimalPoint =
            newValue.indexOf(foundSeparator[0] as string) > -1;
          return; // Allow: only one decimal point
        }
      } else {
        if (newValue.split(e.key).length > 2) {
          // has two or more decimal points
          e.preventDefault();
          return;
        } else {
          this.hasDecimalPoint = newValue.indexOf(e.key) > -1;
          return; // Allow: only one decimal point
        }
      }
    }

    if (e.key === this.negativeSign && this.allowNegatives) {
      newValue = this.forecastValue(e.key);
      if (
        newValue.charAt(0) !== this.negativeSign ||
        newValue.split(this.negativeSign).length > 2
      ) {
        e.preventDefault();
        return;
      } else {
        this.hasNegativeSign = newValue.split(this.negativeSign).length > -1;
        return;
      }
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === " " || isNaN(Number(e.key))) {
      e.preventDefault();
      return;
    }

    newValue = newValue || this.forecastValue(e.key);

    // check the input pattern RegExp
    if (this.regex) {
      if (!this.regex.test(newValue)) {
        e.preventDefault();
        return;
      }
    }

    const newNumber = Number(newValue);
    if (newNumber > this.max || newNumber < this.min) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: any): void {
    if (this.allowPaste === true) {
      let pastedInput: string = "";
      if ((window as { [key: string]: any })["clipboardData"]) {
        // Browser is IE
        pastedInput = (window as { [key: string]: any })[
          "clipboardData"
        ].getData("text");
      } else if (event.clipboardData && event.clipboardData.getData) {
        // Other browsers
        pastedInput = event.clipboardData.getData("text/plain");
      }

      this.pasteData(pastedInput);

      event.preventDefault();
    } else {
      // this prevents the paste
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent): void {
    const textData = event.dataTransfer?.getData("text") ?? "";
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  @HostListener("blur")
  onBlur() {
    if (this.castToNumber) {
      if (this.ngModel && this.ngModel.control) {
        this.ngModel.control.setValue(
          Utils.toNumber(this.ngModel.control.value)
        );
      } else if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.setValue(
          Utils.toNumber(this.ngControl.control.value)
        );
      }
    }

    if (!this.castToNumber && this.decimal && this.keepAlwaysDecimal) {
      if (this.ngModel && this.ngModel.control) {
        this.ngModel.control.setValue(
          Utils.numberFormat(this.ngModel.control.value, {
            decimal: 2,
          })
        );
      } else if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.setValue(
          Utils.numberFormat(this.ngModel.control.value, {
            decimal: 2,
          })
        );
      }
    }
  }

  private pasteData(pastedContent: string): void {
    let sanitizedContent = this.sanitizeInput(pastedContent);
    if (
      sanitizedContent.includes(this.negativeSign) &&
      this.hasNegativeSign &&
      !this.getSelection().includes(this.negativeSign)
    ) {
      return;
    }
    const pasted = document.execCommand("insertText", false, sanitizedContent);
    if (!pasted) {
      if (this.inputElement.setRangeText) {
        const { selectionStart: start, selectionEnd: end } = this.inputElement;
        this.inputElement.setRangeText(
          sanitizedContent,
          start ?? 0,
          end ?? 0,
          "end"
        );
        // Angular's Reactive Form relies on "input" event, but on Firefox, the setRangeText method doesn't trigger it
        // so we have to trigger it ourself.
        if (
          typeof (window as { [key: string]: any })["InstallTrigger"] !==
          "undefined"
        ) {
          this.inputElement.dispatchEvent(
            new Event("input", { cancelable: true })
          );
        }
      } else {
        // Browser does not support setRangeText, e.g. IE
        this.insertAtCursor(this.inputElement, sanitizedContent);
      }
    }
    if (this.decimal) {
      if (Array.isArray(this.decimalSeparator)) {
        const extractedSeparator = this.extractSeparator(
          this.inputElement.value
        );
        this.hasDecimalPoint =
          this.inputElement.value.indexOf(extractedSeparator) > -1;
      } else {
        this.hasDecimalPoint =
          this.inputElement.value.indexOf(this.decimalSeparator as string) > -1;
      }
    }
    this.hasNegativeSign =
      this.inputElement.value.indexOf(this.negativeSign) > -1;
  }

  // The following 2 methods were added from the below article for browsers that do not support setRangeText
  // https://stackoverflow.com/questions/11076975/how-to-insert-text-into-the-textarea-at-the-current-cursor-position
  private insertAtCursor(myField: HTMLInputElement, myValue: string): void {
    const startPos = myField.selectionStart ?? 0;
    const endPos = myField.selectionEnd ?? 0;

    myField.value =
      myField.value.substring(0, startPos) +
      myValue +
      myField.value.substring(endPos, myField.value.length);

    const pos = startPos + myValue.length;
    myField.focus();
    myField.setSelectionRange(pos, pos);

    this.triggerEvent(myField, "input");
  }

  private triggerEvent(el: HTMLInputElement, type: string): void {
    if ("createEvent" in document) {
      // modern browsers, IE9+
      const e = document.createEvent("HTMLEvents");
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    }
  }
  // end stack overflow code

  private sanitizeInput(input: string): string {
    let result = "";
    let regex;
    if (Array.isArray(this.decimalSeparator)) {
      const extractedSeparator = this.extractSeparator(input);
      if (this.decimal && this.isValidDecimal(input) && extractedSeparator) {
        regex = new RegExp(
          `${this.getNegativeSignRegExp()}[^0-9\\${extractedSeparator}]`,
          "g"
        );
      } else {
        regex = new RegExp(`${this.getNegativeSignRegExp()}[^0-9]`, "g");
      }
    } else {
      if (this.decimal && this.isValidDecimal(input)) {
        regex = new RegExp(
          `${this.getNegativeSignRegExp()}[^0-9${this.decimalSeparator}]`,
          "g"
        );
      } else {
        regex = new RegExp(`${this.getNegativeSignRegExp()}[^0-9]`, "g");
      }
    }

    result = input.replace(regex, "");

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength =
        maxLength -
        this.inputElement.value.length +
        (result.includes(`${this.negativeSign}`) ? 1 : 0);
      result = allowedLength > 0 ? result.substring(0, allowedLength) : "";
    }

    if (result && this.decimal && Array.isArray(this.decimalSeparator)) {
      result = this.removeExtraPastedSeparator(this.keptNumberOnly(result));
    }

    return result;
  }

  private getNegativeSignRegExp(): string {
    return this.allowNegatives &&
      (!this.hasNegativeSign || this.getSelection().includes(this.negativeSign))
      ? `(?!^${this.negativeSign})`
      : "";
  }

  private isValidDecimal(string: string): boolean {
    if (Array.isArray(this.decimalSeparator)) {
      const extractedSeparator = this.extractSeparator(string);

      if (!this.hasDecimalPoint) {
        return string.split(extractedSeparator).length <= 2;
      } else {
        // the input element already has a decimal separator
        const selectedText = this.getSelection();
        const selectedTextSeparator = this.extractSeparator(selectedText);

        if (selectedText && selectedText.indexOf(selectedTextSeparator) > -1) {
          return string.split(extractedSeparator).length <= 2;
        } else {
          //custom logic
          const oldValue = this.inputElement.value;
          const incomingValue = string;

          if (this.extractSeparator(oldValue)) {
            return false;
          }
          if (
            !this.extractSeparator(oldValue) &&
            this.extractSeparator(incomingValue)
          ) {
            return true;
          }

          return string.indexOf(extractedSeparator) < 0;
        }
      }
    } else {
      if (!this.hasDecimalPoint) {
        return string.split(this.decimalSeparator as string).length <= 2;
      } else {
        // the input element already has a decimal separator
        const selectedText = this.getSelection();
        if (
          selectedText &&
          selectedText.indexOf(this.decimalSeparator as string) > -1
        ) {
          return string.split(this.decimalSeparator as string).length <= 2;
        } else {
          return string.indexOf(this.decimalSeparator as string) < 0;
        }
      }
    }
  }

  private getSelection(): string {
    return this.inputElement.value.substring(
      this.inputElement.selectionStart ?? 0,
      this.inputElement.selectionEnd ?? 0
    );
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    return (
      oldValue.substring(0, selectionStart) +
      key +
      oldValue.substring(selectionEnd)
    );
  }

  private setDecimalPattern(decimalPoint = 2) {
    //this.pattern = "^d+(.d{1,2})?$";
    this.pattern = new RegExp("^\\d+(\\.\\d{0,2})?$");
  }

  private hasFoundInSeparatorList(val) {
    return (
      Array.isArray(this.decimalSeparator) &&
      this.decimalSeparator.indexOf(val) != -1
    );
  }

  private hasSeparator(val) {
    return val === this.decimalSeparator || this.hasFoundInSeparatorList(val);
  }

  private extractSeparator(val): string {
    return (this.decimalSeparator as string[]).find((sep) => {
      return val.indexOf(sep) > -1;
    });
  }

  private keptNumberOnly(val) {
    const trimExtraSeparator = (input) => {
      var n = 0;
      return input.replace(/\./g, () => (n++ > 0 ? "" : "."));
    };

    if (val && typeof val == "string") {
      return trimExtraSeparator(val.replace(/\,/g, "."));
    }
    return val;
  }

  private removeExtraPastedSeparator(val) {
    if (val && typeof val == "string") {
      return val.replace(/(\.\.*)\./g, "$1");
    }
    return val;
  }

  private updateInputValue(value) {
    if (this.keepOnlyNumber) {
      const newValue = this.keptNumberOnly(value || "");
      if (newValue) {
        this.renderer.setProperty(this.el.nativeElement, "value", newValue);
        this.renderer.setAttribute(this.el.nativeElement, "value", newValue);

        /*if (this.ngControl) {
          this.ngControl.control.setValue(newValue);
        } */
        return newValue;
      }
    }

    return value;
  }
}

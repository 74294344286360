import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { AuthService, AuthGuardService } from "src/app/core/services";
import { GrantRoleType, IRouteMeta } from "../ITypes";

@Injectable({
  providedIn: "root",
})
export class RolePermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authGuardService: AuthGuardService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const routeMeta = next.data as IRouteMeta;

    return of(
      this.authGuardService.canActivate(next, state) as Observable<boolean>
    ).pipe(
      map((val) => {
        return this.hasAllowAccess(routeMeta.accessBy);
      })
    );
  }

  hasAllowAccess(roles: GrantRoleType[]): boolean {
    const hasAccess = this.authService.hasRolePermission(roles);
    if (!hasAccess) {
      this.router.navigateByUrl("/dashboard/forbidden");
    }
    return hasAccess;
  }
}

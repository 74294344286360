export class GtinFormatter {
  static getBarcodeCheckDigit(numericBarcode) {
    let oddSum = 0;
    let evenSum = 0;
    const code = numericBarcode
      .trim()
      .slice(0, -1)
      .split("")
      .reverse()
      .join("");

    for (let i = 0; i < code.length; i++) {
      const digit = parseInt(code[i]);

      if (i % 2 === 0) {
        evenSum += digit;
      } else {
        oddSum += digit;
      }
    }

    const total = evenSum * 3 + oddSum;
    return (10 - (total % 10)) % 10;
  }

  static checkBarcodeEncoding(barcode, encoding) {
    if (barcode) {
      const barcodeStripped = barcode.trim();
      encoding = encoding.toLowerCase();

      if (encoding === "any") {
        return true;
      }

      const barcodeSizes = {
        ean8: 8,
        ean13: 13,
        gtin14: 14,
        upca: 12,
        sscc: 18,
      };
      const barcodeSize = barcodeSizes[encoding];

      return (
        (encoding !== "ean13" || barcodeStripped[0] !== "0") &&
        barcodeStripped.length === barcodeSize &&
        /^\d+$/.test(barcodeStripped) &&
        this.getBarcodeCheckDigit(barcodeStripped) ===
          parseInt(barcodeStripped.slice(-1))
      );
    } else {
      return false;
    }
  }

  static isValid(gtin) {
    if (gtin) {
      const isValidGtin = this.checkBarcodeEncoding(
        gtin.trim().padStart(14, "0"),
        "gtin14"
      );
      return isValidGtin;
    } else {
      return false;
    }
  }

  static padGtin(gtin) {
    if (!gtin) {
      return null;
    } else {
      const gtinStripped = gtin.trim();

      if (gtinStripped.length <= 14) {
        return gtinStripped.padStart(14, "0");
      } else {
        const cutoff = gtinStripped.length - 14;
        const extraSymbols = gtinStripped.slice(0, cutoff);

        if (/^0+$/.test(extraSymbols)) {
          return gtinStripped.slice(cutoff).padStart(14, "0");
        } else {
          return null;
        }
      }
    }
  }

  static compare(a, b) {
    const aPadded = this.padGtin(a);
    const bPadded = this.padGtin(b);

    if (aPadded && bPadded) {
      if (this.isValid(aPadded) && this.isValid(bPadded)) {
        return aPadded === bPadded;
      } else {
        console.log("One of the provided GTIN's is not valid");
        return false;
      }
    } else {
      console.log("One of the provided GTIN's is either missing or None type");
      return false;
    }
  }
}

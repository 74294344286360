import { Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import { AuthService } from "../core/services";
import { finalize, takeUntil } from "rxjs/operators";

@Component({
  selector: "sv-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  // defaults
  isLoading: boolean = false;
  destroyed$ = new Subject();
  credentials = {
    username: "",
    password: "",
  };

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private route: Router
  ) {
    // this.credentials = {
    //   username: "mikko@storeverse.io",
    //   password: "_wrxvUPu@p8=",
    // };
    // this.credentials = {
    //   username: "abdul@lafka.io",
    //   password: "Zz9_shgtH-of7",
    // };
  }

  ngOnInit(): void {}

  async handleLogin() {
    try {
      const { username, password } = this.credentials;
      // validation
      if (!username) {
        this.toastr.warning("Please enter username", "Login");
        return;
      }
      if (!password) {
        this.toastr.warning("Please enter password", "Login");
        return;
      }
      this.isLoading = true;
      this.authService
        .login({
          username,
          password,
        })
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          (res) => {
            this.route.navigate(["/dashboard"]);
          },
          (err) => {
            this.toastr.error(err.message, "Error Occurred");
          }
        );
    } catch (error) {
      this.isLoading = false;
      let message = "Username or Password is not correct";
      if (error.error && error.error.detail) {
        message = error.error.detail;
      }
      this.toastr.error(message, "Error Occurred");
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DlDateAdapterMoment,
  DlDateTimeDateModule,
  DlDateTimePickerModule,
} from "angular-bootstrap-datetimepicker";
import { NgxMaskModule } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

import { DefaultDatepickerComponent } from "./default-datepicker/default-datepicker.component";
import { StandardDatepickerComponent } from "./standard-datepicker/standard-datepicker.component";
import { DatepickerComponent } from "./datepicker.component";
import { DatepickerFormatter } from "../utils";
import { DatepickerAdapter } from "./datepicker-adapter";
import { MomentAdapter } from "./moment-adapter";

@NgModule({
  declarations: [
    DatepickerComponent,
    DefaultDatepickerComponent,
    StandardDatepickerComponent,
  ],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NgxMaskModule,
  ],
  exports: [DatepickerComponent],
  providers: [
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: DlDateAdapterMoment, useClass: MomentAdapter },
    { provide: NgbDateParserFormatter, useClass: DatepickerFormatter },
  ],
})
export class DatepickerModule {}

export const orderFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        // Labels
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa01",
          ref: "deliveryAddress",
          type: "panel",
          required: false,
          label: "Delivery Address",
          labelKey: "ORDER.O004",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              id: null,
              key: "inputGroup_1",
              type: "inputGroup",
              label: "Delivery Name",
              labelKey: "ORDER.O014",
              required: false,
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: [
                {
                  id: null,
                  key: "O030",
                  prop: "default.O030",
                  resolveTo: [{ key: "shipping_firstname", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "First Name",
                  placeholderKey: "ORDER.O031",
                  label: "First Name",
                  labelKey: "ORDER.O031",
                  unique: false,
                  default: null,
                  tooltip: '"name"',
                  description: "O030, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                  validate: true,
                  validators: [{ callback: "required" }],
                  isFloatLabel: true,
                },
                {
                  id: null,
                  key: "O031",
                  prop: "default.O031",
                  resolveTo: [{ key: "shipping_lastname", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "Last Name",
                  placeholderKey: "ORDER.O032",
                  label: "Last Name",
                  labelKey: "ORDER.O032",
                  unique: false,
                  default: null,
                  tooltip: '"name"',
                  description: "O030, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                  validate: true,
                  validators: [{ callback: "required" }],
                  isFloatLabel: true,
                },
              ],
            },
            {
              id: null,
              key: "O032",
              prop: "default.O032",
              resolveTo: [{ key: "shipping_email", type: "textfield" }],
              type: "textfield",
              required: false,
              label: "Delivery Email",
              labelKey: "ORDER.O015",
              placeholder: "Email",
              placeholderKey: "GLOBAL.G008",
              shownPlaceholderAsLabel: true,
              unique: false,
              default: null,
              tooltip: "",
              description: "O032, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }, { callback: "email" }],
              isFloatLabel: true,
            },
            {
              id: null,
              key: "O033",
              prop: "default.O033",
              resolveTo: [{ key: "shipping_phone", type: "textfield" }],
              type: "textfield",
              required: false,
              label: "Delivery Phone",
              labelKey: "ORDER.O016",
              placeholder: "Phone",
              placeholderKey: "GLOBAL.G009",
              unique: false,
              default: null,
              tooltip: "",
              description: "O033, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
              isFloatLabel: true,
            },
            {
              id: null,
              key: "inputGroup_2",
              type: "inputGroup",
              label: "Delivery Address",
              labelKey: "ORDER.O017",
              required: false,
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: [
                {
                  id: null,
                  key: "O034",
                  prop: "default.O034",
                  resolveTo: [{ key: "shipping_street", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "Street",
                  placeholderKey: "ORDER.O032",
                  label: "Street",
                  labelKey: "ORDER.O034",
                  unique: false,
                  default: null,
                  tooltip: "",
                  description: "L009, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                  validate: true,
                  validators: [{ callback: "required" }],
                  isFloatLabel: true,
                },
                {
                  id: null,
                  key: "O035",
                  prop: "default.O035",
                  resolveTo: [{ key: "shipping_city", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "City",
                  placeholderKey: "ORDER.O035",
                  label: "City",
                  labelKey: "ORDER.O035",
                  unique: false,
                  default: null,
                  tooltip: "",
                  description: "L009, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                  validate: true,
                  validators: [{ callback: "required" }],
                  isFloatLabel: true,
                },
                {
                  id: null,
                  key: "O036",
                  prop: "default.O036",
                  resolveTo: [{ key: "shipping_zip", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "ZIP Code",
                  placeholderKey: "ORDER.O036",
                  label: "ZIP Code",
                  labelKey: "ORDER.O036",
                  unique: false,
                  default: null,
                  tooltip: "",
                  description: "L009, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                  validate: true,
                  validators: [
                    { callback: "required" },
                    { callback: "zipCode" },
                  ],
                  isFloatLabel: true,
                },
                {
                  id: null,
                  key: "O037",
                  prop: "default.O037",
                  resolveTo: [{ key: "shipping_country", type: "textfield" }],
                  type: "textfield",
                  required: false,
                  placeholder: "Country",
                  placeholderKey: "ORDER.O037",
                  label: "Country",
                  labelKey: "ORDER.O037",
                  unique: false,
                  default: null,
                  tooltip: "",
                  description: "L009, , ",
                  data: null,
                  hidden: false,
                  protected: true,
                  components: null,
                  isFloatLabel: true,
                },
              ],
            },
          ],
        },
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa02",
          ref: "deliveryTime",
          type: "panel",
          required: false,
          label: "Delivery Time",
          labelKey: "ORDER.O033",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              id: null,
              key: "delivery_date",
              type: "date",
              format_type: "YYYY-MM-DD",
              required: false,
              label: "Delivery Date",
              labelKey: "ORDER.O026",
              resolveTo: [
                { key: "delivery_details.starttime", type: "datetime" },
                { key: "delivery_details.endtime", type: "datetime" },
                { key: "default.O013", type: "datetime" },
                { key: "default.O014", type: "datetime" },
              ],
              prop: "delivery_details.delivery_date",
              placeholder: "",
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
              clearable: true
            },
            {
              id: null,
              key: "expected_delivery_date",
              type: "date",
              required: false,
              label: "Delivery Date",
              labelKey: "ORDER.O026",
              format_type: "YYYY-MM-DD HH:mm:ss",
              clearable: true,
              resolveTo: [
                {
                  key: "delivery_details.expected_delivery_date",
                  type: "datetime",
                },
              ],
              prop: "delivery_details.expected_delivery_date",
              placeholder: "",
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "fromtime",
              prop: "delivery_details.fromtime",
              resolveTo: [
                { key: "delivery_details.starttime", type: "datetime" },
                { key: "default.O013", type: "datetime" },
              ],
              type: "time-picker",
              required: false,
              label: "Delivery Start Time",
              labelKey: "ORDER.O027",
              placeholder: "",
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "totime",
              prop: "delivery_details.totime",
              resolveTo: [
                { key: "delivery_details.endtime", type: "datetime" },
                { key: "default.O014", type: "datetime" },
              ],
              type: "time-picker",
              required: false,
              label: "Delivery End Time",
              labelKey: "ORDER.O028",
              placeholder: "",
              unique: false,
              default: null,
              tooltip: "",
              description: "",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
          ],
        },
      ],
    },
  },
};

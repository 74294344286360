import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, of } from "rxjs";
import { finalize, share, shareReplay, tap } from "rxjs/operators";
import { APP_CONFIG } from "src/app/config";

interface CacheResponse<T> {
  response: HttpResponse<T>;
  lastCall: number; //milliseconds
}

@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
  cache: Map<String, CacheResponse<any>> = new Map();
  cacheRequest: Map<String, Observable<any>> = new Map();

  constructor(@Inject(APP_CONFIG) private config) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const isCached = request.headers.get("cached");

    if (isCached === "true" && request.method === "GET") {
      const cachedResponse: CacheResponse<any> = this.cache.get(
        this.getCacheReqKey(request)
      );

      if (cachedResponse && !this.shouldCallAPI(cachedResponse)) {
        return of(cachedResponse.response.clone());
      } else {
        let observable;
        if (this.cacheRequest.get(this.getCacheReqKey(request))) {
          observable = this.cacheRequest.get(this.getCacheReqKey(request));
        } else {
          observable = next.handle(request);
          this.cacheRequest.set(this.getCacheReqKey(request), observable);
        }
        return observable.pipe(
          tap((stateEvent) => {
            if (stateEvent instanceof HttpResponse) {
              this.cache.set(this.getCacheReqKey(request), {
                response: stateEvent.clone(),
                lastCall: new Date().getTime(),
              });
            }
          }),
          finalize(() => this.cacheRequest.delete(this.getCacheReqKey(request)))
        );
      }
    }

    return next.handle(request);
  }

  private getCacheReqKey(req: HttpRequest<any>) {
    return JSON.stringify({
      url: req.url,
    });
  }

  get cacheRequestTimeout() {
    return this.config.cacheRequestTimeoutMins * 60 * 1000;
  }

  private shouldCallAPI(response: CacheResponse<any>) {
    const now = new Date().getTime();
    return now - response.lastCall > this.cacheRequestTimeout;
  }
}

import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ILocaleTextProps } from "../../types";

@Component({
  selector: "sv-locale-text",
  templateUrl: "./locale-text.component.html",
  styleUrls: ["./locale-text.component.scss"],
})
export class LocaleTextComponent implements OnInit {
  @Input() key: string;
  @Input() defaultText: string;
  @Input() params: Object;
  @Input() resource: boolean = false;
  @Input() viewMode: boolean = true;
  @Input() modeInline: boolean = false;
  @Input() extractKey: boolean = false;

  formatterView = {
    key: "",
    value: "",
  };

  defaultOpt: Partial<ILocaleTextProps> = {};
  debugMode = false;
  textVal;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    let mode = this.route.snapshot.queryParams["mode"];

    if (mode === "debug") {
      this.debugMode = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const params = changes["params"]?.currentValue;
    const resource = changes["resource"]?.currentValue;
    const extractKey = changes["extractKey"]?.currentValue;
    const keyName = changes["key"]?.currentValue;

    if (keyName) {
      this.textVal = this.translateService.instant(keyName);
    }

    if (params) {
      this.defaultOpt["params"] = params;
    }

    if (params) {
      this.defaultOpt["resource"] = resource;
    }

    if (extractKey) {
      this.formatterView.key = this.extractLastSegment(keyName);
    }
  }

  extractLastSegment(str) {
    const lastIndex = str.lastIndexOf(".");

    if (lastIndex !== -1) {
      return str.substring(lastIndex + 1);
    } else {
      return "";
    }
  }
}

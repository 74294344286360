import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import {
  ApiService,
  AuthService,
  HttpQueryService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";
import { userFormSchema } from "src/app/layout/user/write/schema";

import { IQueryParams, IUserRole, IHttpResult } from "src/app/core/ITypes";
import { IUser, IUserForm } from "src/app/commons/ITypes";
import { IUserFormException } from "src/app/layout/user/ITypes";
import { profileFormSchema } from "src/app/layout/dashboard/containers/edit-profile/schema";
import { AppConstant } from "../app.constant";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _patchedAfterUserFormEmitter: Subject<IUser> = new Subject<IUser>();
  patchedAfterUserForm$: Observable<IUser> =
    this._patchedAfterUserFormEmitter.asObservable();

  constructor(
    private api: ApiService,
    private generalService: GeneralService,
    private authService: AuthService,
    private httpQueryService: HttpQueryService
  ) {}

  async save(data: IUser, id = null) {
    try {
      let response;
      let successMsg;

      const { password, ...user } = data;

      const payload = {
        properties: user,
        password,
      };

      if (id) {
        payload["id"] = id;
        if (!payload.password) {
          delete payload.password;
        }
        response = await this.api
          .post(payload, "/user_management/update")
          .toPromise();
        successMsg = `User has been updated successfully`;
      } else {
        response = await this.api
          .post(payload, `/user_management/signup`)
          .toPromise();
        successMsg = `User has been added successfully`;
      }

      const { status, result } = response;
      const userList: IHttpResult<IUser>[] = result;

      if (status === 200 && result) {
        if (!id && userList?.length > 0) {
          const selectedRawUserEntity = userList[0];
          const selectedUserEntity = {
            id: selectedRawUserEntity.id,
            ...selectedRawUserEntity.properties,
          };
          return {
            data: selectedUserEntity,
            message: successMsg,
          };
        }
        return {
          data: null,
          message: successMsg,
        };
      }
    } catch (error) {
      console.log("errorerrorerror", error);
      const userException = error.error as IUserFormException;
      const userMessage =
        userException.detail.Exception || userException.detail.message;

      throw new Error(userMessage);
    }
  }

  async getAll(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const skip = queryParams.offset;
      const searchTerm = queryParams.search;
      const search = {
        email: searchTerm?.trim(),
        username: searchTerm?.trim(),
      };
      const direction = queryParams.direction;
      const order = queryParams.order;

      const querySet = { limit, skip, direction, order, search };
      const queryString = this.httpQueryService.serialize(querySet);

      let apiEndpoint = `/user_management/get_managed_users?${queryString}`;

      const { status, meta, result } = await this.api
        .get(apiEndpoint)
        .toPromise();

      const userList: IHttpResult<IUser>[] = result;

      if (status === 200 && userList) {
        let data: IUser[] = [];

        for (let i = 0; i < userList.length; i++) {
          const res = userList[i];
          let props = res.properties;

          data.push({
            ...props,
            name: `${props.firstName} ${props.lastName}`,
            roles: props.roles.map((item) => {
              const selectedRole = this.findRoleByName(item.role);

              return {
                ...item,
                roleLabel: selectedRole.label,
              };
            }),
            id: res.id,
          });
        }
        return { result: data, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  async getDetail(id: number): Promise<IUser> {
    try {
      const { status, result } = await this.api
        .get(`/user_management/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const props = { ...result.properties };
        delete props.password;
        return props;
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(uuID, id) {
    console.log("uuID, id", uuID, id);
    return this.api.delete(`/user_management/delete/${uuID}/${id}`).toPromise();
  }

  async getFormSchema() {
    try {
      const { status, result } = userFormSchema;

      if (status === 200 && result && result.properties) {
        const panels: Array<any> = result.properties.components;
        return panels;
      }
    } catch (error) {
      throw error;
    }
  }

  async getUserProfileFormSchema() {
    try {
      const { status, result } = profileFormSchema;

      if (status === 200 && result && result.properties) {
        const panels: Array<any> = result.properties.components;
        return panels;
      }
    } catch (error) {
      throw error;
    }
  }

  findStoreByName(name: string) {
    const userAssignedRoles = this.authService.authUser.roles || [];

    return userAssignedRoles.find((item) => item.name == name);
  }

  findRoleByName(name: string) {
    return AppConstant.userRoles.find((item) => name == item.value);
  }

  getAllAvailableRoles() {
    return AppConstant.userRoles;
  }

  getAllAvailableStores() {
    return this.authService.assignedStores.map((item) => {
      return {
        label: item.label,
        value: item.name,
      };
    });
  }

  getTopLevelPrivilegedStores() {
    return this.authService.adminPrivilegedStores.map((item) => {
      return {
        label: item.label,
        value: item.name,
      };
    });
  }

  getLowLevelPrivilegedStores() {
    return this.authService.nonAdminPrivilegedStores.map((item) => {
      return {
        label: item.label,
        value: item.name,
      };
    });
  }

  get nonAdminAuthPrivilegedStores() {
    return this.authService.nonAdminPrivilegedStores;
  }

  getAssignedStoreRolesByChannelID(name: string) {
    const store = this.authService.authUser.roles.find((item: IUserRole) => {
      return item.name == name && item.role == "admin";
    });

    return store ? this.getAllAvailableRoles() : [];
  }

  dispatchAfterPatchedUserForm(selectedUser: IUser) {
    this._patchedAfterUserFormEmitter.next(selectedUser);
  }

  async updateProfile(data: Partial<IUserForm>) {
    try {
      const { password, id, ...user } = data;

      const payload = {
        properties: user,
        password,
      };

      if (!payload.password) {
        delete payload.password;
      }

      const response = await this.api
        .post(payload, `/user_management/update_current_user`)
        .toPromise();

      const { result } = response;

      return {
        data: result,
        message: "User Profile has been updated successfully",
      };
    } catch (error) {
      const userException = error.error as IUserFormException;
      const userMessage =
        userException.detail.Exception || userException.detail.message;

      throw new Error(userMessage);
    }
  }
}

import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";

import { LocaleChangeEvent, LocaleService } from "src/app/core/services";
import { ILocaleText } from "src/app/core/ITypes";
import { Subject, Subscription } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ILocaleTextProps } from "../types";
import { isNullOrEmpty } from "src/app/commons/utils";
import { ActivatedRoute } from "@angular/router";

//  {{ "PRODUCT.P001" | localeTrans : { params: { username: ".." } } }}
@Pipe({
  name: "localeTrans",
  pure: false,
})
export class LocaleTransPipe implements PipeTransform {
  value: string = "";
  opt: Partial<ILocaleTextProps>;
  lastKey: string;
  lastLang: string;
  onLangChange: Subscription | undefined;
  private destroyed$ = new Subject();

  constructor(
    private localeService: LocaleService,
    private _ref: ChangeDetectorRef,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  updateValue(query: string, _lang: string) {
    this.value = query;
    this.lastKey = query;
    this._ref.markForCheck();
  }

  updateValueWithDetectChanges(query: string, lang: string) {
    this.updateValue(query, lang);
    this._ref.detectChanges();
  }

  extractLastSegment(str) {
    const lastIndex = str.lastIndexOf(".");

    if (lastIndex !== -1) {
      return str.substring(lastIndex + 1);
    } else {
      return "";
    }
  }

  transform(
    query: string | ILocaleText,
    opt: Partial<ILocaleTextProps> = null
  ): string {
    const debugMode = this.route.snapshot.queryParams["mode"] === "debug";

    if (isNullOrEmpty(query) && opt?.defaultText) {
      return opt.defaultText;
    }

    if (!query) {
      return query as null;
    }

    if (query && opt?.extractKey) {
      query = this.extractLastSegment(query);
    }

    if (!opt?.resource && typeof query === "string") {
      let text = this.translateService.instant(query, opt?.params);
      let translatedText = text.value ? text.value : text;

      if (opt?.trace && debugMode) {
        translatedText = `{{${query}}} - ${translatedText}`;
      }

      return translatedText;
    }

    const selectedLang = this.localeService.getPreferredLang();

    if (!selectedLang) {
      return query as string;
    }

    if (typeof query === "string") {
      return query;
    }

    const currenVal = query[selectedLang];

    if (currenVal === this.lastKey) {
      return this.lastKey;
    }

    this.lastLang = selectedLang;
    this.opt = opt;

    // store the query, in case it changes
    this.lastKey = currenVal;

    // set the value
    this.updateValue(currenVal, selectedLang);

    // if there is a subscription to onLangChange, clean it

    this._dispose();

    if (!this.onLangChange) {
      this.onLangChange = this.localeService.onLangChange
        .pipe(
          filter((res) => {
            return res.lang === this.lastLang;
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe((event: LocaleChangeEvent) => {
          if (this.lastKey) {
            this.lastKey = null;
            this.updateValue(query[event.lang], event.lang);
          }
        });
    }

    return this.value;
  }

  private _dispose(): void {
    if (typeof this.onLangChange !== "undefined") {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnDestroy(): void {
    this._dispose();
  }
}

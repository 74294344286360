import { IConfirmDialog } from "./confirm-dialog.model";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfirmDialogService {
  options: IConfirmDialog = {
    title: "Confirmation",
    content: "Are you sure you want to delete",
    cancelButtonText: "No",
    confirmButtonText: "Yes",
  };

  confirmCallback: Function;
  cancelCallback: Function;

  _confirmEmitter: Subject<any> = new BehaviorSubject<any>(false);
  confirmSubscriber$: Observable<any> = this._confirmEmitter.asObservable();

  constructor() {}

  open(options: IConfirmDialog, ConfirmCallback: any, CancelCallback: any) {
    this.options = { ...this.options, ...options };
    this.confirmCallback = ConfirmCallback;
    this.cancelCallback = CancelCallback;
    this._confirmEmitter.next(true);
  }
}

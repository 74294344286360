import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LocaleTextComponent } from "./components/locale-text/locale-text.component";
import { LocaleTransPipe } from "./pipes/locale-trans.pipe";

@NgModule({
  declarations: [LocaleTextComponent, LocaleTransPipe],
  imports: [CommonModule],
  exports: [LocaleTextComponent, LocaleTransPipe],
})
export class LocalizationCommonModule {}

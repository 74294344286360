import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslationLoader } from "./translation-loader";
import { HttpClient } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [TranslateModule],
})
export class LocalizationModule {}

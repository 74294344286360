import { NgControl, NG_VALIDATORS } from "@angular/forms";
import { Directive, forwardRef, HostBinding, Self } from "@angular/core";

@Directive({
  selector: "[svValidate]",
  providers: [
    //{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateDirective) , multi: true },
  ],
})
export class ValidateDirective {
  constructor(@Self() private ngControl?: NgControl) {}

  @HostBinding("class.is-invalid")
  get borderClass() {
    return this.ngControl.touched && this.ngControl.invalid;
  }
}

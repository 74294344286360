import {
  Directive,
  AfterContentChecked,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";

@Directive({
  selector: "[svResizeWatcher]",
})
export class ResizeWatcherDirective implements AfterContentChecked {
  constructor(
    private table: DatatableComponent,
    public ref: ChangeDetectorRef
  ) {}

  @Input() resize = false;

  private latestWidth: number;

  ngAfterContentChecked() {
    if (
      this.resize &&
      this.table &&
      this.table.element.clientWidth !== this.latestWidth
    ) {
      this.latestWidth = this.table.element.clientWidth;

      if (window.innerWidth < 1000) {
        this.table.scrollbarH = true;
      } else {
        this.table.scrollbarH = false;
      }

      this.table.recalculate();
      this.ref.markForCheck();
    }
  }
}

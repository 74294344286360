import { MomentTimeFormatter } from "./../utils/moment-time-formatter";
import { InputControlAbstract } from "./../../core/abstracts/input-control.abstract";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from "@angular/core";
import { NgbTimeAdapter, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "sv-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
    { provide: NgbTimeAdapter, useClass: MomentTimeFormatter },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent
  extends InputControlAbstract<string>
  implements OnInit
{
  @Input() placeholder = "HH:MM:SS";
  @Input() name = "";
  pickerValue;
  @Input() inputClass;

  ngOnInit(): void {}

  openPicker(popupCntx) {
    popupCntx.open();
    /* if (popupCntx.isOpen()) {
      popupCntx.close();
    } else {
      popupCntx.open();
    } */
  }

  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ greeting, language });
    }
  }

  onSelectTime() {
    if (this.value) {
      const parsedTime = moment(this.value, ["HH:mm:ss"]);
      const date = moment();
      date.set({
        hour: parsedTime.get("hour") || 0,
        minute: parsedTime.get("minute") || 0,
        second: parsedTime.get("second") || 0,
      });

      this.value = date.format("HH:mm:ss");
    }
  }

  private adjustTime(parsedTime) {
    let hour = parsedTime.hours();
    let minute = parsedTime.minutes();

    if (hour > 23) {
      hour = 0;
    }

    if (minute > 59) {
      minute = 0;
    }

    parsedTime.hours(hour);
    parsedTime.minutes(minute);

    return parsedTime;
  }
}

<div>
  <!-- --------------------------- general table --------------------------- -->
  <ngx-datatable
    svResizeWatcher
    [resize]="resize"
    #table
    class="bootstrap"
    columnMode="force"
    rowHeight="auto"
    [footerHeight]="50"
    [rows]="rows"
    [columns]="columns"
    [limit]="limit"
    [offset]="offset"
    [count]="count"
    [externalPaging]="externalPaging"
    [sorts]="sorting"
    (sort)="sort($event)"
    (page)="page($event)"
    [loadingIndicator]="loading"
    [selectionType]="chkboxSelection ? SelectionType.checkbox : null"
    [cssClasses]="{
      sortAscending: 'fa fa-caret-up',
      sortDescending: 'fa fa-caret-down',
      pagerLeftArrow: 'fa fa-angle-left',
      pagerRightArrow: 'fa fa-angle-right',
      pagerPrevious: 'fa fa-angle-double-left',
      pagerNext: 'fa fa-angle-double-right'
    }"
    [scrollbarH]="showHorizontalScroll || scrollBarHorizontal"
  >
    <ng-container *ngIf="translate">
      <ng-container *ngFor="let col of columns">
        <ngx-datatable-column
          [name]="
            debug === false
              ? (col.name | localeTrans)
              : (col.name | localeTrans : { extractKey: true })
          "
          headerClass="header"
          [prop]="col.prop"
          [cellTemplate]="col.cellTemplate"
          [maxWidth]="col.maxWidth"
          [minWidth]="col.minWidth"
          [width]="col.width"
          [sortable]="col.sortable"
          [comparator]="col.comparator"
        >
        </ngx-datatable-column>
      </ng-container>
    </ng-container>

    <!-- Row Detail Template -->
    <ngx-datatable-row-detail [rowHeight]="300" #myDetailRow>
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-row-detail-template
      >
        <ng-container
          *ngTemplateOutlet="
            rowDetailTemplate;
            context: { row: row, expanded: expanded }
          "
        >
        </ng-container>
      </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="count"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div class="page-count" style="padding: 0 10px">
          {{ "GLOBAL.G067" | localeTrans : { trace: true } }}:
          {{ rowCount }} &nbsp;•&nbsp;
          {{ "GLOBAL.G068" | localeTrans : { trace: true } }}:
          {{ pageSize }} &nbsp;•&nbsp;
          {{ "GLOBAL.G069" | localeTrans : { trace: true } }}:
          {{ curPage }} &nbsp;
          <select
            class="page-limit"
            (change)="handleOnPageLimitChange($event.target.value)"
          >
            <option
              *ngFor="let option of pageLimitOptions"
              [value]="option.value"
              [selected]="option.value == limit"
            >
              {{ option.value }}
              {{ "GLOBAL.G070" | localeTrans : { trace: true } }}
            </option>
          </select>
        </div>

        <datatable-pager
          *ngIf="rowCount > pageSize"
          [pagerLeftArrowIcon]="cssClasses.pagerLeftArrow"
          [pagerRightArrowIcon]="cssClasses.pagerRightArrow"
          [pagerPreviousIcon]="cssClasses.pagerPrevious"
          [pagerNextIcon]="cssClasses.pagerNext"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          (change)="changeRowsLimit($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<ng-template #confirmationTemplate>
  <div class="modal-header">
    <span>{{ options.title }}</span>
    <button class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ options.content }}
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-md btn-confirm-dialog btn-cancel"
      (click)="onCancel()"
    >
      {{ options?.cancelButtonText }}
    </button>
    <button class="btn btn-md btn-danger" (click)="onConfirm()">
      {{ options?.confirmButtonText }}
    </button>
  </div>
</ng-template>

<div class="lang-switcher-container">
  <sv-dropdown
    class="dropdown-selectbox"
    [(ngModel)]="selectedLang"
    [items]="langOptions"
    bindLabel="label"
    bindValue="value"
    [clearable]="false"
    [searchable]="false"
    [hideSelected]="true"
    (change)="changeLanguage()"
  >
    <ng-template svDropdownLabel let-item="item">
      <i class="fa fa-globe"></i>
      <b>{{ item.label }}</b>
    </ng-template>
  </sv-dropdown>
</div>

import { IDisplayGroup } from "src/app/commons/ITypes";
import { IProductDisplayGroupForm } from "../ITypes";
import { ProductDisplayGroupCriteria } from "./ProductDisplayGroupCriteria";

export class ActiveDisplayGroupProcessor {
  static handle(
    selected: IProductDisplayGroupForm[],
    displaygroups: IDisplayGroup[]
  ) {
    const activeWithAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findActive(item, displaygroups);
    });

    if (activeWithAssortment) {
      return activeWithAssortment;
    }

    const activeWithoutAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findActiveFreeAssortment(
        item,
        displaygroups
      );
    });

    if (activeWithoutAssortment) {
      return activeWithoutAssortment;
    }
  }
}

export class InActiveDisplayGroupProcessor {
  static handle(
    selected: IProductDisplayGroupForm[],
    displaygroups: IDisplayGroup[]
  ) {
    const hiddenWithAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findHiddenWithAssortment(
        item,
        displaygroups
      );
    });

    if (hiddenWithAssortment) {
      return hiddenWithAssortment;
    }

    const hiddenWithoutAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findHiddenFreeAssortment(
        item,
        displaygroups
      );
    });

    if (hiddenWithoutAssortment) {
      return hiddenWithoutAssortment;
    }
  }
}

export class ArchivedDisplayGroupProcessor {
  static handle(
    selected: IProductDisplayGroupForm[],
    displaygroups: IDisplayGroup[]
  ) {
    const archivedWithAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findArchivedWithAssortment(
        item,
        displaygroups
      );
    });

    if (archivedWithAssortment) {
      return archivedWithAssortment;
    }

    const archivedWithoutAssortment = selected.find((item) => {
      return ProductDisplayGroupCriteria.findArchivedFreeAssortment(
        item,
        displaygroups
      );
    });

    if (archivedWithoutAssortment) {
      return archivedWithoutAssortment;
    }
  }
}

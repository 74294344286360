import { Injectable } from "@angular/core";
import { ApiService } from "src/app/core/services";
// import { unitsFormSchema } from "src/mockdata";
import { IQueryParams } from "src/app/core/ITypes";

@Injectable({
  providedIn: "root",
})
export class UnitService {
  constructor(private api: ApiService) {}

  // async getFormSchema() {
  //   try {
  //     const { status, result } = unitsFormSchema;

  //     if (status === 200 && result && result.properties) {
  //       const panels: Array<any> = result.properties.components;
  //       return panels;
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getUnits(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      const search = queryParams.search?.trim();
      let apiEndpoint = `/unit?limit=${limit}&skip=${offset}`;

      if (search) {
        apiEndpoint += `&search=title=${search}`;
      }

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        const r = result.map((r) => ({ id: r.id, ...r.properties }));
        return { result: r, meta };
      }
    } catch (error) {
      throw error;
    }
  }
}

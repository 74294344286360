import { InjectionToken } from "@angular/core";

export const LOCALE_CONFIG = new InjectionToken("locale.config");

export const Locales = [
  { label: "FI", value: "fi" },
  { label: "EN", value: "en" },
];

export enum LocaleCode {
  EN = "en",
  FI = "fi",
}

import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DisplayGroupDropdownComponent } from "./composition/display-group-dropdown/display-group-dropdown.component";

import { NgSelectModule } from "@ng-select/ng-select";
import { LocalizationCommonModule } from "src/app/core/lib/localization";

@NgModule({
  declarations: [DisplayGroupDropdownComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    LocalizationCommonModule,
  ],
  exports: [DisplayGroupDropdownComponent],
})
export class SharedModule {}

import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";

import { CacheService } from "./cache.service";
import { JwtService } from "./jwt.service";
import { APP_CONFIG } from "src/app/config";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  private idleTimer: any;
  private lastActivityTime: number;
  private idleTimeoutSubject: Subject<void> = new Subject<void>();
  private mousemoveHandler: EventListenerOrEventListenerObject;
  private keypressHandler: EventListenerOrEventListenerObject;

  constructor(
    private jwtService: JwtService,
    private cacheService: CacheService,
    @Inject(APP_CONFIG) private config,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.initialize();
  }

  get idleDuration() {
    // 45 minutes in milliseconds
    return this.config.idleInterval * 60 * 1000;
  }

  get window(): Window | null {
    return this.doc.defaultView;
  }

  initialize() {
    this.listenActivity();
    this.resetTimer();
  }

  resetTimer() {
    clearTimeout(this.idleTimer);

    if (!this.jwtService.session) {
      return;
    }

    this.lastActivityTime = Date.now();
    this.cacheService.set(
      this.config.lastActivityKey,
      this.lastActivityTime.toString()
    );
    this.idleTimer = setTimeout(() => {
      this.clearIdleInterval();
    }, this.idleDuration);
  }

  listenActivity() {
    if (!this.jwtService.session) {
      return;
    }

    this.mousemoveHandler = this.resetTimer.bind(this);
    this.keypressHandler = this.resetTimer.bind(this);

    this.window.addEventListener("mousemove", this.mousemoveHandler);
    this.window.addEventListener("keypress", this.keypressHandler);
  }

  removeIdleSession() {
    this.cacheService.remove(this.config.lastActivityKey);
    clearTimeout(this.idleTimer);
    this.clearIdleTimeoutSubscription();

    this.window.removeEventListener("mousemove", this.mousemoveHandler);
    this.window.removeEventListener("keypress", this.keypressHandler);
  }

  checkIdleTimeoutRemaining() {
    const lastAcivityTime = this.cacheService.get(this.config.lastActivityKey);
    const timeSinceLastActivity = Date.now() - parseInt(lastAcivityTime, 10);

    return timeSinceLastActivity >= this.idleDuration ? false : true;
  }

  getRemainingIdleTime() {
    const lastAcivityTime = this.cacheService.get(this.config.lastActivityKey);
    const timeSinceLastActivity = Date.now() - parseInt(lastAcivityTime, 10);
    const remainingIdleTime = Math.max(
      this.idleDuration - timeSinceLastActivity,
      0
    );
    return remainingIdleTime;
  }

  onIdleTimeout(): Observable<void> {
    return this.idleTimeoutSubject.asObservable();
  }

  clearIdleTimeoutSubscription(): void {
    this.idleTimeoutSubject.unsubscribe();
  }

  clearIdleInterval() {
    this.idleTimeoutSubject.next();
  }

  disposeWatcherWhenTimeout() {
    if (!this.checkIdleTimeoutRemaining()) {
      this.clearIdleInterval();
    }
  }
}

<section class="login-block">
  <div class="login-sec">
    <img src="assets/logo.png" class="logo align-top" alt="logo" />
    <h2 class="text-center" i18n="@@e3804211b84e54201385438983c5cf1a729f9475">
      Login
    </h2>
    <form class="login-form" (keyup.enter)="handleLogin()">
      <div class="form-group">
        <label
          class="text-uppercase"
          i18n="@@244aae9346da82b0922506c2d2581373a15641cc"
        >
          Username
        </label>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="credentials.username"
        />
      </div>
      <div class="form-group">
        <label
          class="text-uppercase"
          i18n="@@c32ef07f8803a223a83ed17024b38e8d82292407"
        >
          Password
        </label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="credentials.password"
        />
      </div>

      <div class="form-check">
        <label class="form-check-label">
          <!-- <input type="checkbox" class="form-check-input" />
          <small i18n="@@6511301d6af86149cac968fcaa7c93f128c9ab77">
            Remember Me
          </small> -->
        </label>
        <a
          class="btn btn-primary btn-login float-right"
          (click)="handleLogin()"
          *ngIf="!isLoading"
          i18n="@@729411b7f2d3f8d9112be6db06e97d485d6efdae"
        >
          Submit
        </a>
        <button
          disabled
          type="button"
          class="btn btn-primary btn-login float-right"
          *ngIf="isLoading"
        >
          <span
            role="status"
            aria-hidden="true"
            class="spinner-border spinner-border-sm"
          ></span>
          Loading...
        </button>
      </div>
    </form>
    <div class="copy-text" i18n="@@da4a5c66abf24158891d54cecd08d0dd9c660313">
      <strong>Store</strong>verse Backoffice
    </div>
  </div>
</section>

export { _deepClone } from "src/app/commons/utils";

/* export const _diff = (a, b) => {
  const objDiff = (a, b) => {
    let diff: any = Object.keys(a).reduce((p, c, i) => {
      if (a[c] === b[c]) {
        return p;
      }
      p[c] = b[c];
      return p;
    }, {});
    if (!Object.keys(diff).length) {
      return;
    }
    diff.id = a.id;
    return diff;
  };

  return a.reduce((p, c, i) => {
    let diff = objDiff(c, b[i]);
    diff && p.push({ ...a[i], ...diff });
    return p;
  }, []);
}; */

export const _diff = (a, b) => {
  const objDiff = (a, b) => {
    if (!a || !b) {
      return [];
    }

    let diff = Object.keys(b).reduce((p, c) => {
      const aValue = a[c];
      const bValue = b[c];

      if (Array.isArray(aValue) && Array.isArray(bValue)) {
        const arrayDiff = compareArrays(aValue, bValue);
        if (arrayDiff.length > 0) {
          p[c] = arrayDiff;
        }
      } else if (typeof aValue === "object" && typeof bValue === "object") {
        const nestedDiff = objDiff(aValue, bValue);
        if (Object.keys(nestedDiff).length > 0) {
          p[c] = nestedDiff;
        }
      } else if (aValue !== bValue) {
        p[c] = bValue;
      }

      return p;
    }, {});

    return diff;
  };

  const compareArrays = (a, b) => {
    return a.reduce((p, c, i) => {
      const diff = objDiff(c, b[i]);
      if (Object.keys(diff).length > 0) {
        p.push({ ...b[i], ...diff });
      }
      return p;
    }, []);
  };

  return compareArrays(a, b);
};

export const isNullOrEmpty = (value) => {
  return value === "" || value === null || value === undefined;
};

export const getProductFieldColumnWidth = (key: string) => {
  if (key === "D002") {
    return 120;
  } else if (key === "D001") {
    return 235;
  } else {
    return 200;
  }
};

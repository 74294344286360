import {
  Component,
  Input,
  forwardRef,
  OnInit,
  ContentChildren,
  TemplateRef,
  ViewChild,
  ElementRef,
  ContentChild,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import {
  NgOptionTemplateDirective,
  DropdownLabelDirective,
  NgHeaderTemplateDirective,
  NgFooterTemplateDirective,
  NgOptgroupTemplateDirective,
  NgNotFoundTemplateDirective,
  NgTypeToSearchTemplateDirective,
  NgLoadingTextTemplateDirective,
  NgMultiLabelTemplateDirective,
  NgTagTemplateDirective,
  NgLoadingSpinnerTemplateDirective,
} from "./ng-templates.directive";
import { LocaleService } from "src/app/core/services";

export type GroupValueFn = (
  key: string | object,
  children: any[]
) => string | object;
export type CompareWithFn = (a: any, b: any) => boolean;
export type DropdownPosition = "bottom" | "top" | "auto";
interface DropdownExactMatch {
  value: boolean;
  prop: string;
}

@Component({
  selector: "sv-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  private _items: any[] = [];

  @Input()
  set items(val: any[]) {
    if (val) {
      this._items = val.map((_item) => {
        /* if(_item.labelKey){
          _item.label = this.localeService.(_item.labelKey);
        } */

        return _item;
      });
    }
  }

  get items() {
    return this._items;
  }

  @Input() compareHandler: CompareWithFn;

  @Input() placeholder: string;

  @Input() placeholderKey: string;

  @Input() bindLabel = "label";

  @Input() bindValue: string;

  @Input() style: string = "min-width: 200px";

  @Input() name: string;

  @Input() searchFn: Function = null;

  @Input() groupBy: string | Function;

  @Input() groupValue: GroupValueFn;

  @Input() exactMatch: boolean = true;

  @Input() readonly: boolean = false;

  @Input() clearable: boolean = true;

  @Input() searchable: boolean = true;

  @Input() hideSelected: boolean = false;

  @Input() searchKeys: string[];

  // custom templates
  @ContentChild(NgOptionTemplateDirective, { read: TemplateRef })
  optionTemplate: TemplateRef<any>;
  @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef })
  optgroupTemplate: TemplateRef<any>;
  @ContentChild(DropdownLabelDirective, { read: TemplateRef })
  labelTemplate: TemplateRef<any>;
  @ContentChild(NgMultiLabelTemplateDirective, { read: TemplateRef })
  multiLabelTemplate: TemplateRef<any>;
  @ContentChild(NgHeaderTemplateDirective, { read: TemplateRef })
  headerTemplate: TemplateRef<any>;
  @ContentChild(NgFooterTemplateDirective, { read: TemplateRef })
  footerTemplate: TemplateRef<any>;
  @ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef })
  notFoundTemplate: TemplateRef<any>;
  @ContentChild(NgTypeToSearchTemplateDirective, { read: TemplateRef })
  typeToSearchTemplate: TemplateRef<any>;
  @ContentChild(NgLoadingTextTemplateDirective, { read: TemplateRef })
  loadingTextTemplate: TemplateRef<any>;
  @ContentChild(NgTagTemplateDirective, { read: TemplateRef })
  tagTemplate: TemplateRef<any>;
  @ContentChild(NgLoadingSpinnerTemplateDirective, { read: TemplateRef })
  loadingSpinnerTemplate: TemplateRef<any>;

  @Output("change") changeEvent = new EventEmitter();

  /**
   * Holds the current value of the slider
   */
  value: any;

  ngOnInit() {
    if (Array.isArray(this.searchKeys) && this.searchKeys.length) {
      this.searchFn = this.searchByKeyCallback.bind(this);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const bindValue = changes["bindValue"]?.currentValue;
    const exactMatch = changes["exactMatch"]?.currentValue;

    if (bindValue && !exactMatch) {
      const prop = bindValue || "value";
      this.compareHandler = this.defaultOptionMatch(prop);
    }
  }

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  constructor(private localeService: LocaleService) {}

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: number): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleChange() {
    this.changeEvent.emit(this.value);
  }

  defaultOptionMatch(prop) {
    return (item, selected) => item[prop] == selected;
  }

  searchByKeyCallback(term: string, item: any) {
    const pattern = new RegExp(term, "gi");

    for (const property of this.searchKeys) {
      if (pattern.test(item[property])) {
        return true;
      }
    }

    return false;
  }
}

import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

import { Utils } from "src/app/commons/utils";
import { CHANNEL_CONFIG, NAV_MENU } from "src/app/config";
import { IChannel, IMenu, ISiteConfig } from "../ITypes";

@Injectable({
  providedIn: "root",
})
export class SiteConfigService {
  private selectedConfig: ISiteConfig;
  private menuItems = new BehaviorSubject<IMenu[]>([]);

  menuItems$ = this.menuItems.asObservable();

  constructor(
    @Inject(NAV_MENU) private menu: IMenu[],
    @Inject(CHANNEL_CONFIG) private channels: IChannel[],
    private _http: HttpClient
  ) {}

  async load(merchantId) {
    const selectedTenant = this.channels.find(
      (channel) => channel.id === +merchantId
    );
    const configPath = selectedTenant.configPath || "panda";

    return new Promise(async (resolve, reject) => {
      await import(`src/app/tenants/${configPath}/config.js`)
        .then((res) => {
          const result = Utils.cloneObj(res);

          this.setConfig(result.config);

          resolve(this.selectedConfig);
        })
        .catch((e) => {
          reject("Sorry, config is not found");
        });
    });
  }

  get options() {
    return this.selectedConfig;
  }

  setItem(key, val) {
    Utils.updateObjProp(this.selectedConfig, key, val);
  }

  getItem(key) {
    return Utils.getPropByString(this.selectedConfig || {}, key);
  }

  private setConfig(config) {
    this.selectedConfig = config;
    this.setMenuItems();
  }

  private setMenuItems() {
    const menuItems = Utils.cloneObj(this.menu).map((item) => {
      const sideNav = this.selectedConfig.side_nav;

      if (sideNav && sideNav.enable_menus) {
        const isEnabled = sideNav.enable_menus.indexOf(item.slug) != -1;

        if (isEnabled) {
          item.visible = true;
        }
      }

      if (sideNav && sideNav.disableMenus) {
        const isDisabled = sideNav.disableMenus.indexOf(item.slug) != -1;

        if (isDisabled) {
          item.visible = false;
        }
      }

      return item;
    });

    this.menuItems.next(menuItems);
  }
}

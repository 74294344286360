<ng-container *ngIf="compareHandler">
  <ng-select
    [items]="items"
    name="value"
    [bindLabel]="bindLabel"
    [placeholder]="
      placeholderKey ? (placeholderKey | localeTrans) : placeholder
    "
    [bindValue]="bindValue"
    [readonly]="readonly"
    [compareWith]="compareHandler"
    [(ngModel)]="value"
    [searchFn]="searchFn"
    (ngModelChange)="updateChanges()"
    (change)="handleChange()"
    [style]="style"
    [clearable]="clearable"
    [searchable]="searchable"
    [hideSelected]="hideSelected"
  >
    <ng-container *ngIf="labelTemplate">
      <ng-template ng-label-tmp let-item="item">
        <ng-template
          [ngTemplateOutlet]="labelTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        >
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-select>
</ng-container>
<ng-container *ngIf="!compareHandler">
  <ng-select
    [items]="items"
    name="value"
    [bindLabel]="bindLabel"
    [placeholder]="
      placeholderKey ? (placeholderKey | localeTrans) : placeholder
    "
    [bindValue]="bindValue"
    [readonly]="readonly"
    [(ngModel)]="value"
    [searchFn]="searchFn"
    (ngModelChange)="updateChanges()"
    (change)="handleChange()"
    [style]="style"
    [clearable]="clearable"
    [searchable]="searchable"
  >
    <ng-container *ngIf="labelTemplate">
      <ng-template ng-label-tmp let-item="item">
        <ng-template
          [ngTemplateOutlet]="labelTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        >
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-select>
</ng-container>

import { IProduct } from "src/app/commons/ITypes";
import { Utils } from "src/app/commons/utils";
import { IProductItem, ICampaignProductItem } from "../ITypes";
import { DISPLAY_GROUP_FIELDS } from "../constants";

export class ProjectProductItem implements Partial<ICampaignProductItem> {
  /*
  D001: string;
  D002: boolean;
  P000: string | number;
  P003: string | number; */
  C001: any;
  id: number;
  details?: Partial<IProduct>;
  revisedState: Partial<IProductItem> = {};

  constructor(product, productMetaData: IProduct = null) {
    Object.assign(this, product);

    if (productMetaData) {
      this.details = productMetaData;
      this.details.P000 = Utils.numberFormat(this.details.P000, {
        decimal: 2,
      });
      this.details.P003 = Utils.numberFormat(this.details.P003, {
        decimal: 2,
      });
    }
  }

  static create(product, productMetaData = null) {
    return new ProjectProductItem(product, productMetaData);
  }

  static getFormKeys() {
    return [
      { key: "D002", type: "string" },
      { key: "D002", type: "bool" },
      { key: "P000", type: "number" },
      { key: "P003", type: "number" },
    ];
  }

  static parseFieldInstance(productInputChanges, enabledFields: string[] = []) {
    return;
  }

  setRevisedDetails(productInputChanges, enabledFields) {
    const item = {};

    enabledFields.forEach((fieldKey) => {
      let value;

      if (DISPLAY_GROUP_FIELDS.indexOf(fieldKey) != -1) {
        if (!item["D000"]) {
          item["D000"] = [
            {
              [fieldKey]: Utils.getPropByString(
                productInputChanges,
                `D000.0.${fieldKey}`
              ),
            },
          ];

          return false;
        }

        item["D000"][0][fieldKey] = this.getDefaultValue(
          Utils.getPropByString(productInputChanges, `D000.0.${fieldKey}`)
        );

        return false;
      }

      item[fieldKey] = this.getDefaultValue(productInputChanges[fieldKey]);
    });

    (<ICampaignProductItem>this).revisedState = item;
  }

  static cloneItems(items: ICampaignProductItem[]) {
    return items.map((item) => {
      return this.clone(item);
    });
  }

  static clone(instance: ICampaignProductItem) {
    const clone = Object.assign({}, instance);
    Object.setPrototypeOf(clone, ProjectProductItem.prototype);

    return clone;
  }

  static reinitializedCopy(instance: ICampaignProductItem) {
    const clonedInstance = this.clone(instance);

    clonedInstance.revisedState = null;

    return instance;
  }

  static toParseEntity(instance: ICampaignProductItem, fields: string[] = []) {
    const entity = {
      id: instance.id,
      C001: instance.C001,
      details: null,
      revisedState: {},
    };

    const { details, revisedState, id, ...productMetaDetails } = instance;

    fields.forEach((itemKey) => {
      entity[itemKey] = instance[itemKey];
    });

    if (productMetaDetails) {
      entity.details = { ...productMetaDetails };
    }

    if (revisedState) {
      entity.revisedState = { ...revisedState };
    }

    return this.create(entity);
  }

  getDefaultValue(value) {
    if (typeof value === "undefined") {
      return null;
    }

    return value;
  }
}

export const isNumber = (o) => {
  return typeof o == "number";
};

export const isString = (o) => {
  return typeof o == "string";
};

export const isObject = (obj) => {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
};

export const trim = (o) => {
  if (typeof o === "string") {
    return o.replace(/^[\s\t\r\n]+|[\s\t\r\n]+$/g, "");
  }
  return o;
};

export const isNumeric = (value) => {
  // return /^[+-]?\d+(\.\d*)?$/.test(value);
  return /^[+-]?(?:\d+\.?\d*|\.\d+)$/.test(value);
};

export const isDotOrComma = (value) => {
  return /^[.,]$/.test(value);
};

export const removePad = (o) => {
  if (typeof o == "string") {
    return o.replace(/^\s+|\s+$/g, "");
  }
  return o;
};

export const isEmptyVal = (value) => {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value === false
  ) {
    return true;
  } else {
    return false;
  }
};

export const renderFn = (str) => {
  try {
    const castTypes = {
      undefined: undefined,
      null: null,
      true: true,
      false: false,
    };

    Object.keys(castTypes).forEach((castType) => {
      str = str.replace(`'${castType}'`, castTypes[castType]);
    });

    let func = new Function("return " + str);

    if (func.call(this)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const leftPad = (number, targetLength) => {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
};

export const _deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const hasAllEmptyValues = (input) => {
  if (Array.isArray(input)) {
    if (input.length === 0) {
      return true;
    }

    for (const item of input) {
      if (!hasAllEmptyValues(item)) {
        return false;
      }
    }

    return true;
  } else if (typeof input === "object" && input !== null) {
    for (const key in input) {
      if (input.hasOwnProperty(key) && !hasAllEmptyValues(input[key])) {
        return false;
      }
    }

    return true;
  } else {
    return !input && input !== 0;
  }
};

export const isNullOrEmpty = (value) => {
  return value === "" || value === null || value === undefined;
};

import { Component, OnInit } from "@angular/core";

import { AuthService } from "src/app/core/services";

@Component({
  selector: "sv-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  redirectLink: string;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.redirectLink = this.authService.authUser ? "/dashboard" : "/";
  }
}

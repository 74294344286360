import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ApiService, HttpQueryService } from "src/app/core/services";
import { GeneralService } from "./general.service";

import { IQueryParams } from "src/app/core/ITypes";
import { IProject, IProjectProduct } from "../ITypes";
import { ICampaign } from "./../ITypes";
import { ProductEditor, Project } from "src/app/layout/campaign/entity";
import { Utils } from "src/app/commons/utils";
import { ProductService } from "./product.service";
import { ProductInputField } from "src/app/layout/campaign/entity/ProductInputField";

@Injectable({
  providedIn: "root",
})
export class CampaignService {
  _products = [];
  _internalProducts = [];
  selectedProject = null;

  private saveProduct = new Subject();
  public saveProduct$ = this.saveProduct.asObservable();

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private httpQueryService: HttpQueryService,
    private productService: ProductService
  ) {}

  submitProduct(products) {
    this.saveProduct.next(products);
  }

  addProduct(newProduct) {
    this._products.push(newProduct);
  }

  updateProducts(
    productList: ProductInputField[],
    enabledFields: string[] = []
  ) {
    productList.forEach((product) => {
      const existedProductIndex = this._internalProducts.findIndex(
        (item) => product.id == item.id
      );

      if (existedProductIndex !== -1) {
        if (product.arePropertiesEmpty(enabledFields)) {
          this._internalProducts.splice(existedProductIndex, 1);
        } else {
          this._internalProducts[existedProductIndex] = {
            ...this._internalProducts[existedProductIndex],
            ...product,
          };
        }
      } else {
        if (ProductInputField.areEmpty(product, enabledFields)) {
          return false;
        }

        this._internalProducts.push(product);
      }
    });
  }

  setProducts(productList) {
    this._products = Utils.cloneObj(productList);
  }

  setProject(project) {
    this.selectedProject = project;
    this.setProducts(project.products);
    this._internalProducts = Utils.cloneObj(project.products);
  }

  getProducts() {
    return this._products;
  }

  async fetchProject(id): Promise<IProject> {
    try {
      const { status, result } = await this.apiService
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const props = result.properties as IProject;
        const project: IProject = {
          //project_id: result.id,
          ...props,
          id: result.id,
          start_date: props.start_date || props.publish_date,
        };
        //this.setProject(project);
        return project;
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchAllProjects(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      const filter = queryParams.filter;
      // const search =
      //   (queryParams.search && `email=${queryParams.search}`) || null;
      const search = queryParams.search;
      const direction = queryParams.direction;
      const order = queryParams.order;
      const hasLatestProject = queryParams.hasLatestProject;

      const queryString = this.httpQueryService.serialize({
        limit,
        skip: offset,
        direction,
        order,
        filter,
      });

      let apiEndpoint = `/campaign?${queryString}`;

      const { status, result, meta } = await this.apiService
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;
          let dataset = {
            ...props,
            start_date: props.start_date || props.publish_date,
            input: {
              parsed_start_date: Utils.toDateParse(
                props.start_date || props.publish_date
              ),
            },
            id: res.id,
          };

          data.push(dataset);
        }

        let filteredData = [];

        if (!hasLatestProject) {
          filteredData = data;
        } else {
          filteredData = data.filter((item) => {
            return this.generalService.isFutureDate(item.start_date);
          });
        }

        return {
          result: filteredData,
          meta: { ...meta, total: filteredData.length },
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async storeProducts(productFields: string[] = []) {
    const products = Utils.cloneObj(this._internalProducts);

    try {
      const postProducts: IProjectProduct[] = ProductEditor.fromModel(
        this._internalProducts,
        productFields
      );

      const payload = {
        properties: Project.toRequestEntity(this.selectedProject, postProducts),
      };

      let requestUrl, response, responseMsg;

      const id = this.selectedProject.id;
      requestUrl = `/${id}`; //`/campaign`;
      payload["id"] = id;
      response = await this.apiService.put(payload, "/campaign").toPromise();
      responseMsg = `Campaign has been updated successfully`;

      const { status, result } = response;

      if (status === 200 && result) {
        this.setProducts(products);
        const props = result.properties;
        props["id"] = result.id;
        return {
          data: props,
          message: responseMsg,
          status: true,
        };
      } else {
        return {
          data: null,
          message: "Something went wrong",
          status: false,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async publishProject(projectID: number, isPublished: boolean) {
    try {
      const payload = {
        properties: {
          is_published: +isPublished,
        },
        id: projectID,
      };

      const response = await this.apiService
        .patch(payload, "/campaign")
        .toPromise();

      const responseMsg = `Campaign has been ${
        isPublished ? "published" : "unpublished"
      } successfully`;

      const { status, result } = response;

      if (status === 200 && result) {
        return {
          data: this.selectedProject,
          message: responseMsg,
          status: true,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async saveProject(data, id = null, shouldPatch = false) {
    let requestUrl = `/campaign`;
    let response;
    let responseMsg;

    const payload = {
      properties: {
        ...data,
      },
    };

    if (id) {
      requestUrl = `/${id}`;
      payload["id"] = id;
      if (shouldPatch) {
        response = await this.apiService
          .patch(payload, "/campaign")
          .toPromise();
      } else {
        response = await this.apiService.put(payload, "/campaign").toPromise();
      }
      responseMsg = `Campaign has been updated successfully`;
    } else {
      response = await this.apiService.post(payload, "/campaign").toPromise();
      responseMsg = `Campaign has been created successfully`;
    }

    const { status, result } = response;

    if (status === 200 && result) {
      const props = result.properties || result;
      props["id"] = result.id;

      const dataset = {
        ...props,
        input: {
          parsed_start_date: Utils.toDateParse(props.start_date),
        },
      };

      return {
        data: dataset,
        message: responseMsg,
      };
    }
  }

  async isCampaignExists(campaign: Partial<ICampaign>, id = null) {
    try {
      const params = {
        limit: 1,
        filter: [
          { key: "start_date" as keyof ICampaign, value: campaign.start_date },
          { key: "end_date" as keyof ICampaign, value: campaign.end_date },
          { key: "title" as keyof ICampaign, value: campaign.title },
        ],
      };

      const { result } = await this.fetchAllProjects(params);
      const campaigns = result || [];
      let status = result.length > 0;
      let msgLiteral = "Campaign with these same details already exists";

      if (status) {
        let foundCampaign = campaigns.find((item) => {
          return item.id == id;
        });

        if (foundCampaign) {
          return { status: false, msg: "" };
        }
      }

      return { status, msg: status ? msgLiteral : "" };
    } catch (e) {
      return { status: false, msg: "" };
    }
  }

  clearProject() {
    this.selectedProject = null;
    this._products = [];
    this._internalProducts = [];
  }

  async getProjectAssociatedProducts(products = []) {
    try {
      const filterQuery = products.reduce((prev, productID, index) => {
        return (
          prev + "id=" + productID + (products.length - 1 !== index ? "&" : "")
        );
      }, "");
      const params = Utils.serializeQuery({ limit: 500, offset: 0 });
      const queryParams = params + "&" + filterQuery;

      let apiEndpoint = `/product?${queryParams}`;

      const { status, result, meta } = await this.apiService
        .get(apiEndpoint)
        .toPromise<any>();

      if (status === 200 && result) {
        let data = [];

        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;

          data.push({
            id: res.id,
            ...props,
          });
        }
        return { result: data, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  deleteProject(id): Promise<any> {
    return this.apiService.delete(`/campaign/${id}`).toPromise();
  }

  getProductFieldSelectorSchema() {
    const { status, result } = this.productService.productFormSchema;

    if (status === 200 && result && result.properties) {
      const panels = result.properties.components;

      return panels.filter((item) => ["pictures"].indexOf(item.ref) === -1);
    }

    return [];
  }

  async fetchProductFields(projectID) {
    try {
      const { status, result, meta } = await this.apiService
        .get(`/campaign/${projectID}/product-fields`)
        .toPromise();

      if (status === 200 && result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  }

  async saveProductFields(projectID, data) {
    try {
      const payload = {
        properties: {
          ...data,
        },
      };
      const response = await this.apiService
        .put(payload, `/campaign/${projectID}/product-fields`)
        .toPromise();
      const responseMsg = `Product fields has been updated successfully`;

      const { status, result } = response;

      if (status === 200 && result) {
        return {
          data: result.properties,
          message: responseMsg,
        };
      }
    } catch (error) {
      throw error;
    }
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TagInputModule } from "ngx-chips";
import { BreadcrumbModule } from "angular-crumbs";
import { FileUploadModule } from "ng2-file-upload";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule,
} from "angular-bootstrap-datetimepicker";
import {
  NgbModalModule,
  NgbTimepickerModule,
  NgbPopoverModule,
  NgbCollapseModule,
} from "@ng-bootstrap/ng-bootstrap";

import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { DatatableComponent } from "./datatable/datatable.component";
import { DynamicInputComponent } from "./dynamic-input/dynamic-input.component";
import { SpinnerLoaderComponent } from "./spinner-loader/spinner-loader.component";
import { InfoBlockComponent } from "./info-block/info-block.component";
import { ModalComponent } from "./modal/modal.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { TooltipModule, TooltipOptions } from "ng2-tooltip-directive";

import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { NumberInputDirective } from "./directives/number-input.directive";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { SharedModule as DisplayGroupSharedModule } from "./../layout/display-group/shared/shared.module";
import { ValidateDirective } from "./directives/validate.directive";
import { MarkFormTouchedDirective } from "./directives/mark-form-touched.directive";
import { FormErrorComponent } from "./form-error/form-error.component";
import { ResizeWatcherDirective } from "./directives/resize-watcher.directive";
import { TimePickerComponent } from "./time-picker/time-picker.component";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { IfViewportSizeDirective } from "./directives/if-viewport-size.directive";
import { TrimDirective } from "./directives/trim.directive";
import { DatepickerModule } from "./datepicker/datepicker.module";
import { DatepickerFormatter } from "./utils";
import { FormSchemaService } from "../core/services";
import { TextfieldComponent } from "./textfield/textfield.component";
import { PriceFormatterPipe } from "./pipes/price-formatter.pipe";
import { RoleSwitcherComponent } from "./layout/role-switcher/role-switcher.component";
import { AccessPermissionDirective } from "./directives/access-permission.directive";
import { LangSwitcherComponent } from "./layout/lang-switcher/lang-switcher.component";
import { DropdownLabelDirective } from "./dropdown/ng-templates.directive";
import { LocalizationCommonModule } from "../core/lib/localization";
import {
  ChannelHiddenDirective,
  ChannelVisibilityDirective,
} from "./directives/channel-permission.directive";

let modules = [
  NavbarComponent,
  SidebarComponent,
  BreadcrumbComponent,
  DatatableComponent,
  DynamicInputComponent,
  SpinnerLoaderComponent,
  InfoBlockComponent,
  ModalComponent,
];

const TOOLTIP_CONFIG: TooltipOptions = {
  "show-delay": 100,
  "hide-delay": 0,
};

const SHARED_MODULES = [
  NgbModalModule,
  NgbPopoverModule,
  NgbCollapseModule,
  LocalizationCommonModule,
];

const FEATURE_SHARED_MODULES = [
  DisplayGroupSharedModule,
  NgxMaskModule.forRoot(),
  NgbTimepickerModule,
];

const LOCAL_COMPONENTS = [RoleSwitcherComponent, LangSwitcherComponent];

const SHARED_COMPONENTS = [
  ConfirmDialogComponent,
  DropdownComponent,
  FormErrorComponent,
  TimePickerComponent,
  TextfieldComponent,
];
const DIRECTIVES = [
  NumberInputDirective,
  ValidateDirective,
  MarkFormTouchedDirective,
  IfViewportSizeDirective,
  TrimDirective,
  AccessPermissionDirective,
  DropdownLabelDirective,
  ChannelHiddenDirective,
  ChannelVisibilityDirective,
];
const PIPES = [PriceFormatterPipe];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    FileUploadModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    TagInputModule,
    BreadcrumbModule,
    NgSelectModule,
    TooltipModule.forRoot(TOOLTIP_CONFIG),
    ...SHARED_MODULES,
    ...FEATURE_SHARED_MODULES,
    DatepickerModule,
  ],
  exports: [
    modules,
    NgSelectModule,
    TooltipModule,
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    NgxMaskModule,
    DatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    TagInputModule,
  ],
  declarations: [
    modules,
    ...SHARED_COMPONENTS,
    ...LOCAL_COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ResizeWatcherDirective,
  ],
  entryComponents: [],
  providers: [DatepickerFormatter, FormSchemaService],
})
export class CommonsModule {}

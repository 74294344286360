import {
  Directive,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  Output,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  Optional,
} from "@angular/core";
import { NgControl, NgModel } from "@angular/forms";
@Directive({
  selector: "[svTrim]",
})
export class TrimDirective {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngModel: NgModel,
    @Optional() private ngControl: NgControl
  ) {}

  @HostListener("blur")
  onBlur() {
    let value = this.ngModel.model;

    if (value) {
      value = value.trim();
      this.renderer.setProperty(this.elementRef.nativeElement, "value", value);
      this.renderer.setAttribute(this.elementRef.nativeElement, "value", value);
      this.ngModel.update.emit(value);

      if (this.ngControl) {
        this.ngControl.control.setValue(value);
      }
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, "value", null);
      this.renderer.setAttribute(this.elementRef.nativeElement, "value", null);
      this.ngModel.update.emit("");

      if (this.ngControl) {
        this.ngControl.control.setValue("");
      }
    }
  }
}

import { Component, OnInit, Input } from "@angular/core";

import { ModalService } from "../services/modal.service";

@Component({
  selector: "sv-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  // defaults
  @Input() heading: string;
  @Input() visible = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.setModalState = false;
  }
}

import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { LocaleService } from "src/app/core/services";
import { LocaleCode } from "src/app/config";

@Component({
  selector: "sv-lang-switcher",
  templateUrl: "./lang-switcher.component.html",
  styleUrls: ["./lang-switcher.component.scss"],
})
export class LangSwitcherComponent implements OnInit {
  selectedLang: LocaleCode;
  langOptions = [];
  private destroyed$ = new Subject();

  constructor(private localeService: LocaleService) {}

  ngOnInit(): void {
    this.langOptions = this.localeService.getAvailableLocales();

    this.localeService
      .getLanguageStream()
      .pipe(
        filter((res) => res !== this.selectedLang),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        this.selectedLang = res as LocaleCode;
      });
  }

  changeLanguage() {
    this.localeService.setPreferredLang(this.selectedLang);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

import { HttpClient } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import localeFi from "@angular/common/locales/fi";

import { RoutingModule } from "./core/routing/routing.module";
import { AuthModule } from "./auth/auth.module";
import { CommonsModule } from "./commons/commons.module";
import { FirebaseModule } from "./core/lib/firebase/firebase.module";
import { LocalizationModule } from "./core/lib/localization/localization.module";

import { AppComponent } from "./app.component";

registerLocaleData(localeFi);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    RoutingModule,
    FormsModule,
    AuthModule,
    BrowserAnimationsModule,
    CoreModule,
    CommonsModule,
    FirebaseModule,
    LocalizationModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

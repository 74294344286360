import { DisplayGroupStatus } from "src/app/layout/display-group/ITypes";
import { IDisplayGroup } from "src/app/commons/ITypes";
import { IProductDisplayGroupForm } from "../ITypes";

export class ProductDisplayGroupCriteria {
  static findActive(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findActive(selected, item)
    );
  }

  static findActiveFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findActiveFreeAssortment(selected, item)
    );
  }

  static findHiddenWithAssortment(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findHiddenWithAssortment(selected, item)
    );
  }

  static findHiddenFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findHiddenFreeAssortment(selected, item)
    );
  }

  static findArchivedWithAssortment(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findArchivedWithAssortment(selected, item)
    );
  }

  static findArchivedFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroups: IDisplayGroup[]
  ) {
    return displaygroups.find((item) =>
      DisplayGroupFilterCriteria.findArchivedFreeAssortment(selected, item)
    );
  }
}

class DisplayGroupFilterCriteria {
  static findActive(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      selected.D002 == true &&
      displaygroup.active != DisplayGroupStatus.ARCHIVE &&
      displaygroup.active != DisplayGroupStatus.HIDE
    );
  }

  static findActiveFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      !selected.D002 &&
      displaygroup.active != DisplayGroupStatus.ARCHIVE &&
      displaygroup.active != DisplayGroupStatus.HIDE
    );
  }

  static findHiddenWithAssortment(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      selected.D002 == true &&
      displaygroup.active == DisplayGroupStatus.HIDE
    );
  }

  static findHiddenFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      !selected.D002 &&
      displaygroup.active == DisplayGroupStatus.HIDE
    );
  }

  static findArchivedWithAssortment(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      selected.D002 == true &&
      displaygroup.active == DisplayGroupStatus.ARCHIVE
    );
  }

  static findArchivedFreeAssortment(
    selected: IProductDisplayGroupForm,
    displaygroup: IDisplayGroup
  ) {
    return (
      +selected.D001 == displaygroup.D001 &&
      !selected.D002 &&
      displaygroup.active == DisplayGroupStatus.ARCHIVE
    );
  }
}

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span
    class="nav-mobile-menu-btn side-menu-btn open"
    (click)="toggleSideMenu()"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </span>
  <span class="navbar-brand">
    <a [href]="storeUrl" target="_blank">
      <img
        src="assets/logo-wide.png"
        class="d-inline-block align-top"
        alt="logo"
      />
    </a>
    <span class="separator">|</span>
    <!-- <a class="store-link fa fa-link" [href]="storeUrl" target="_blank"></a> -->
    <sv-role-switcher></sv-role-switcher>
  </span>
  <span
    class="nav-mobile-menu-btn account-btn"
    data-toggle="collapse"
    (click)="toggleNavbar({ dropdownVisible: true })"
  >
    <i class="fas fa-user"></i>
  </span>

  <div
    [ngbCollapse]="isNavbarCollapsed"
    class="collapse navbar-collapse profile-nav"
    id="navbarSupportedContent"
  >
    <span class="mr-auto"></span>
    <sv-lang-switcher></sv-lang-switcher>
    <ul class="navbar-nav">
      <li
        class="nav-item dropdown"
        [ngClass]="{ show: isActionDropdownVisible || !isNavbarCollapsed }"
      >
        <button
          id="navbarDropdown"
          class="nav-link btn btn-link dropdown-toggle profile-name-item"
          (click)="toggleActionDropdown()"
        >
          {{ (authUser$ | async)?.name }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-right profile-menu"
          [ngClass]="{ show: isActionDropdownVisible || !isNavbarCollapsed }"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="dropdown-item"
            routerLink="/dashboard"
            (click)="closeNavbar()"
          >
            {{ "GLOBAL.G071" | localeTrans : { trace: true } }}
          </a>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            routerLink="/dashboard/profile"
            (click)="closeNavbar()"
          >
            {{ "GLOBAL.G072" | localeTrans : { trace: true } }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="handleLogout()">{{
            "GLOBAL.G073" | localeTrans : { trace: true }
          }}</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

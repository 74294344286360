<div
  class="textfield-container"
  [ngClass]="containerClass"
  [class.textfield-container--required]="
    isMandatory &&
    (inputModelRef.touched || controlRef?.touched) &&
    inputModelRef?.errors?.required
  "
  [class.form-group]="hasInputGroup"
>
  <label *ngIf="label" [class.required]="isMandatory || markRequired">
    <sv-locale-text [key]="labelKey" [defaultText]="label"></sv-locale-text>
  </label>
  <input
    #controlRef="ngModel"
    class="form-control"
    type="text"
    [name]="name"
    [(ngModel)]="value"
    [readonly]="readonly"
    [attr.placeholder]="
      placeholderKey
        ? (placeholderKey | localeTrans : { trace: true })
        : placeholder
    "
    [ngClass]="inputClass"
    (keyup)="handleChange()"
    (blur)="onBlur()"
  />
  <small
    *ngIf="
      isMandatory &&
      (inputModelRef.touched || controlRef?.touched) &&
      inputModelRef?.errors?.required
    "
  >
    <sv-locale-text key="FORM_ERRORS.FE001"></sv-locale-text>
  </small>
</div>

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "src/app/core/services";

import { DocumentRefService } from "src/app/commons/utils";
import { IAuthUserSession } from "src/app/core/ITypes";

@Component({
  selector: "sv-role-switcher",
  templateUrl: "./role-switcher.component.html",
  styleUrls: ["./role-switcher.component.scss"],
  providers: [DocumentRefService],
})
export class RoleSwitcherComponent implements OnInit {
  stores = [];
  selectedStore: number;
  loading = false;
  authUser$: Observable<IAuthUserSession>;
  routesException = /(forbidden)/gi;

  constructor(
    private authService: AuthService,
    private router: Router,
    private document: DocumentRefService
  ) {
    this.selectedStore = this.authService.authUser.store.channelId;
    this.populateDropdownOptions();
    this.authUser$ = this.authService.authUser$;
  }

  ngOnInit(): void {}

  async changeRole() {
    try {
      this.loading = true;
      const changedStore = this.authService.assignedStores.find((item) => {
        return item.channelId == this.selectedStore;
      });
      const { token } = await this.authService.createCustomTokenByChannel(
        changedStore.channelId
      );
      this.authService.refreshToken({
        token,
        channelId: changedStore.channelId,
        role: changedStore.role,
      });

      this.document.getLocation().replace("/dashboard");
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  populateDropdownOptions() {
    this.stores = this.authService.assignedChannelsOptions;
  }
}

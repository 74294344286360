import { Injectable } from "@angular/core";
import { HttpRespone, IHttpApiResponse, IHttpResult } from "../ITypes";
import { Utils } from "src/app/commons/utils";

interface IParamsInput {
  keys: string[];
  fullMatch: Array<string[]>;
}

@Injectable({
  providedIn: "root",
})
export class CustomSearchQueryService {
  constructor() {}

  find(response: IHttpApiResponse<any>, search: string, params: IParamsInput) {
    const foundResults = (response.result as IHttpResult<any>[]).filter(
      (rowItem) => {
        const dataset = rowItem.properties;
        const searchCombination = this.splitAndCleanSpaces(search)
          .map((term) => {
            return this.escapeRegExp(term);
          })
          .join("|");

        const pattern = new RegExp(`(${searchCombination})`, "i");

        if (params.fullMatch) {
          const foundSearchedParam = this.findFullMatchByCombination(
            pattern,
            dataset,
            params.fullMatch
          );

          if (foundSearchedParam) {
            return true;
          }
        }

        const foundSearchedParam = params.keys.find((paramPathKey) =>
          this.findMatchByItemKey(pattern, dataset, paramPathKey)
        );

        if (foundSearchedParam) {
          return true;
        }

        return false;
      }
    );

    response.result = foundResults;

    return response;
  }

  private findFullMatchByCombination(
    pattern: RegExp,
    rowItem,
    itemKey: Array<string[]>
  ) {
    return itemKey.find((keyCombination) => {
      if (keyCombination.length === 1) {
        return keyCombination.some((keyName) => {
          return pattern.test(Utils.getPropByString(rowItem, keyName));
        });
      }

      return keyCombination.every((keyName) => {
        return pattern.test(Utils.getPropByString(rowItem, keyName));
      });
    });
  }

  private findMatchByItemKey(pattern: RegExp, rowItem, paramPathKey) {
    return pattern.test(Utils.getPropByString(rowItem, paramPathKey));
  }

  private escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  private splitAndCleanSpaces(inputString): string[] {
    if (!inputString) {
      return [];
    }

    let parts = inputString.split(" ");

    let cleanedParts = parts.filter((part) => part !== "");

    return cleanedParts;
  }
}

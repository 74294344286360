import { NavigatorService } from "src/app/commons/services";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "sv-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() translate: boolean = false;
  // default
  queryParams: any;

  constructor(
    private route: ActivatedRoute,
    @Optional() private navigatorService: NavigatorService
  ) {
    this.queryParams = this.route.snapshot.queryParams;
  }

  ngOnInit() {}

  handleReturnQueryParams(url: string) {
    if (url !== "/dashboard") {
      return this.queryParams;
    }
    return {};
  }

  console(route) {
    console.log(route);
  }

  ngOnDestroy() {
    if (this.navigatorService) {
      this.navigatorService.unsubcribe();
    }
  }
}

import { Utils } from "src/app/commons/utils";
import { IProductDisplayGroupForm } from "../ITypes";

export class ProductDisplayGroup {
  static create(product) {
    return {
      D001: product?.D001 || null,
      D002: product?.D002 || null,
    };
  }

  static getValidDisplaygroups(
    productDisplaygroups: IProductDisplayGroupForm[],
    opt: { hirerachyLevel?: boolean } = null
  ) {
    const displayGroups = Utils.uniqueListByKey(
      productDisplaygroups.filter((item) => {
        return item.D001;
      }),
      "D001"
    );

    if (opt?.hirerachyLevel) {
      return this.mapParentToDisplayGroupDataset(displayGroups);
    }

    return displayGroups;
  }

  static mapParentToDisplayGroupDataset(
    productDisplaygroups: IProductDisplayGroupForm[]
  ) {
    const parentDisplayGroups = productDisplaygroups.map((item) => {
      const D001 = item.D001.toString();
      return {
        ...item,
        D001: D001.substring(0, 3),
      };
    });
    const filterParentDisplayGroups = Utils.uniqueListByKey(
      parentDisplayGroups,
      "D001"
    );

    return [...productDisplaygroups, ...filterParentDisplayGroups];
  }

  static selectDisplayGroups(productDisplaygroups: IProductDisplayGroupForm[]) {
    return productDisplaygroups.filter((item) => {
      return item.D001.length == 6;
    });
  }

  static findDisplaygroupHaveAssortment(
    displaygroups: IProductDisplayGroupForm[]
  ): IProductDisplayGroupForm | null {
    const initialDisplaygroup = displaygroups[0];

    if (initialDisplaygroup.D002) {
      return initialDisplaygroup;
    }

    const foundDisplaygroupHaveAssortment = displaygroups.find(
      (item) => item.D002
    );

    if (foundDisplaygroupHaveAssortment) {
      return foundDisplaygroupHaveAssortment;
    }

    return initialDisplaygroup;
  }
}

import { InjectionToken } from "@angular/core";

export const CHANNEL_CONFIG = new InjectionToken("channel.config");

export const Channels = [
  { id: 1, title: "Panda", key: "panda", configPath: "panda" },
  { id: 3, title: "Luomun Tarina", key: "lmt", configPath: "lmt" },
  { id: 4, title: "Taffel", key: "taffel" },
  { id: 5, title: "Kauppahalli24 Business", key: "kh24", configPath: "kh24" },
  {
    id: 6,
    title: "Kauppahalli24 Beta",
    key: "kh24-beta",
    configPath: "kh24-beta",
  },
  { id: 7, title: "Pakasta Mua", key: "pakastamua", configPath: "pakastamua" },
  { id: 8, title: "Seulo", key: "seulo", configPath: "seulo" },
  { id: 10, title: "Estradi", key: "estradi", configPath: "estradi" },
  {
    id: 11,
    title: "Olutmaailma",
    key: "olutmaailma",
    configPath: "olutmaailma",
  },
  { id: 12, title: "Jaloviini", key: "jaloviini", configPath: "jaloviini" },
];

export enum ChannelType {
  PANDA = 1,
  LMT = 3,
  KH24 = 5,
  KH24_BETA = 6,
  PAKASTAMUA = 7,
  SEULO = 8,
  ESTRADI = 10,
  OLUTMAAILMA = 11,
  JALOVIINI = 12,
}

export const INACTIVE_CHANNEL_ID = 2;

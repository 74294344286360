export const vendorFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        {
          key: "code",
          type: "number",
          dataType: "int",
          required: true,
          label: "Vendor Id",
          labelKey: "VENDOR.V000",
          placeholder: "Vendor Id",
          placeholderKey: "VENDOR.V000"
        },
        {
          key: "name",
          type: "textfield",
          required: true,
          label: "Title",
          labelKey: "VENDOR.V001",
          placeholder: "Title",
          placeholderKey: "VENDOR.V001"
        },
        {
          id: null,
          key: "type",
          type: "dropdown",
          label: "Type",
          labelKey: "VENDOR.V002",
          default: 1,
          placeholder: "Select Type",
          placeholderKey: "VENDOR.V006",
          required: true,
          data: {
            values: [
              { label: "Regular",
                labelKey: "VENDOR.V003", value: 1 },
              { label: "Premium",
                labelKey: "VENDOR.V004", value: 2 },
            ],
          },
          clearable: false,
        },
        {
          id: null,
          key: "active",
          type: "bool",
          label: "Active",
          labelKey: "VENDOR.V005",
          default: true,
        },
      ],
    },
  },
};

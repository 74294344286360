import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  // defaults
  private isModalVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  set setModalState(value: boolean) {
    this.isModalVisible.next(value);
  }

  get getModalState(): boolean {
    return this.isModalVisible.getValue();
  }

  get observeModalState(): Observable<boolean> {
    return this.isModalVisible.asObservable();
  }
}

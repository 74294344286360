import { DashboardService } from "src/app/commons/services/dashboard.service";
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { SiteConfigService } from "src/app/core/services";
import { Observable } from "rxjs";
import { IMenu } from "src/app/core/ITypes";
@Component({
  selector: "sv-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  // defaults
  isSidebarActive: boolean = false;
  isRouteActive: boolean = false;
  currentRoute: string = null;
  routes: Observable<IMenu[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private siteConfigService: SiteConfigService,
    private dashboardService: DashboardService
  ) {
    // set the currect route
    this.router.events.subscribe((route) => {
      if (this.route instanceof NavigationEnd) {
        this.currentRoute = this.route.url.split("?")[0];
      }
    });

    this.routes = this.siteConfigService.menuItems$;
  }

  ngOnInit(): void {}

  toggleSidebar() {
    this.isSidebarActive = !this.isSidebarActive;
  }

  onClickMenuRoute() {
    this.dashboardService.toggleMobileSidebar();
  }
}

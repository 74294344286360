import { Injectable } from "@angular/core";

import { NgbTimeAdapter, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

@Injectable()
export class MomentTimeFormatter extends NgbTimeAdapter<string> {
  /* hour = 0;
  minute = 0;
  second = 0;
	readonly DT_FORMAT = 'HH:mm:ss'; */

  fromModel(value: string | null = "::00"): NgbTimeStruct | null {
    //console.log("value", value);
    if (!value) {
      return null;
    }

    const split = value.split(":");
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10),
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    const res =
      time != null
        ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
        : null;

    return res;
  }
}

import {
  Directive,
  OnDestroy,
  Input,
  ViewContainerRef,
  TemplateRef,
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Subscription } from "rxjs";

type Size = "small" | "gtSmall" | "medium" | "gtMedium" | "large" | "gtLarge";

const config = {
  small: ["(max-width: 767px)"],
  gtSmall: ["(min-width: 768px)"],
  medium: ["(max-width:991px)"],
  gtMedium: ["(min-width:992px)"],
  large: ["(max-width: 1199px)"],
  gtLarge: ["(min-width: 1200px)"],
};

@Directive({
  selector: "[svIfViewportSize]",
})
export class IfViewportSizeDirective implements OnDestroy {
  private subscription = new Subscription();

  @Input("svIfViewportSize") set size(value: Size) {
    this.subscription.unsubscribe();
    this.subscription = this.observer
      .observe(config[value])
      .subscribe(this.updateView);
  }

  constructor(
    private observer: BreakpointObserver,
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  updateView = ({ matches }: BreakpointState) => {
    if (matches && !this.vcRef.length) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else if (!matches && this.vcRef.length) {
      this.vcRef.clear();
    }
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Inject, Injectable } from "@angular/core";

import { MyCookieService } from "./cookie.service";
import { CacheService } from "./cache.service";

import { IAuthSessionStorage } from "../ITypes";

import { APP_CONFIG } from "src/app/config/app.config";

import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  expirationDate;
  accessToken: string;

  constructor(
    private cookieService: MyCookieService,
    private cacheService: CacheService,
    @Inject(APP_CONFIG) private config
  ) {}

  get sessionKey() {
    return this.config.authKey;
  }

  get session(): IAuthSessionStorage {
    const session = this.cacheService.get(
      this.config.authKey
    ) as IAuthSessionStorage;

    if (session) {
      return {
        ...session,
      };
    }

    return;
  }

  setAccessToken(val: string) {
    this.accessToken = val;
  }

  getLocalStorage(): IAuthSessionStorage {
    return this.cacheService.get(this.config.authKey);
  }

  getToken(): string {
    if (this.accessToken) {
      return this.accessToken;
    }

    const tokenValue = this.cacheService.get(this.config.authKey);
    return (tokenValue && tokenValue.token) || null;
  }

  saveToken(token: string, option: Partial<IAuthSessionStorage> = null) {
    const expiryInDays = 60;
    const expiryInHours = 24;
    const expiresIn = expiryInDays * expiryInHours * 60 * 60; //1296000

    this.expirationDate = new Date(new Date().getTime() + expiresIn * 1000);

    if (this.accessToken != token) {
      this.accessToken = token;
    }

    this.cacheService.set(this.config.authKey, {
      expires: this.expirationDate,
      token,
      ...option,
    });

    this.cookieService.set(
      this.config.authKey,
      token,
      this.expirationDate,
      "/"
    );
  }

  setRefreshToken(sessionStorageChanges: Partial<IAuthSessionStorage>) {
    const session = this.session;
    const expirationDate = new Date(session.expires);

    const { token: refreshToken } = sessionStorageChanges;

    this.expirationDate = expirationDate;

    if (this.accessToken != refreshToken) {
      this.accessToken = refreshToken;
    }

    this.cacheService.set(this.config.authKey, {
      ...session,
      ...sessionStorageChanges,
      expires: this.expirationDate,
    });

    this.cookieService.set(
      this.config.authKey,
      refreshToken,
      expirationDate,
      "/"
    );
  }

  patchTokenDetails(sessionStorageChanges: Partial<IAuthSessionStorage>) {
    const session = this.session;

    if (!session) {
      return;
    }

    this.cacheService.set(this.config.authKey, {
      ...session,
      ...sessionStorageChanges,
    });
  }

  destroyToken() {
    this.cookieService.delete(this.config.authKey, "/");
    this.cacheService.remove(this.config.authKey);
    this.accessToken = null;
  }

  getExpiryDate(): Date {
    const session = this.cacheService.get(this.config.authKey);
    if (session) {
      return session.expires;
    }
    return;
  }

  getRemainingSessionExpiryTime(): number {
    const expiryDate = this.getExpiryDate();
    if (expiryDate) {
      const expiryTimeMilliseconds = moment
        .duration(moment(expiryDate).diff(moment()))
        .asMilliseconds();
      return expiryTimeMilliseconds;
    }
    return 0;
  }

  getFormattedExipryTime() {
    return moment(this.expirationDate).format("YYYY-MM-DD h:mm:ss");
  }

  getLastRefreshTokenTime(): string {
    const session = this.cacheService.get(this.config.authKey);
    if (session) {
      return session.token_refresh_time as string;
    }
    return;
  }
}

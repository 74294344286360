import { Injectable } from "@angular/core";

import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class MyCookieService {
  /**
   * Creates an instance of MyCookieService.
   * @param {CookieService} cookieService
   * @memberof MyCookieService
   */
  constructor(private cookieService: CookieService) {}

  /**
   *  this is to save a cookie
   * @param {string} name
   * @param {string} value
   * @param {(number | Date)} [expires=365]
   * @param {string} [path]
   * @param {string} [domain]
   * @param {boolean} [secure]
   * @param {("Lax" | "Strict")} [sameSite]
   * @memberof MyCookieService
   */
  set(
    name: string,
    value: string,
    expires: number | Date = 365,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: "Lax" | "Strict"
  ): void {
    this.cookieService.set(name, value, expires, path);
  }

  /**
   * this is to check if cookie exist
   * @param {string} name
   * @returns {boolean}
   * @memberof MyCookieService
   */
  check(name: string): boolean {
    return this.cookieService.check(name);
  }

  /**
   * this is to get all cookies
   * @returns {Object} Cookies
   * @memberof MyCookieService
   */
  getAll(): {} {
    return this.cookieService.getAll();
  }

  /**
   * this is to get cookie
   * @returns {string} Cookie
   * @memberof MyCookieService
   */
  get(key: string): string {
    return this.cookieService.get(key);
  }

  /**
   *  this is to delete a cookie
   * @param {string} name
   * @param {string} [path]
   * @param {string} [domain]
   * @memberof MyCookieService
   */
  delete(name: string, path?: string, domain?: string): void {
    this.cookieService.delete(name, path, domain);
  }

  /**
   *  this is to delete all cookies
   * @param {string} [path]
   * @param {string} [domain]
   * @memberof MyCookieService
   */
  deleteAll(path?: string, domain?: string): void {
    this.cookieService.deleteAll(path, domain);
  }
}

import { Injectable } from "@angular/core";
import { Utils, _deepClone, isNullOrEmpty } from "src/app/commons/utils";
import { IQueryParams } from "../ITypes";

@Injectable({
  providedIn: "root",
})
export class HttpQueryService {
  constructor() {}

  serialize(params: IQueryParams, opt?: { mappedKeys?: Object }) {
    params = _deepClone(params);
    let defaultOpt = { prefix: "", mappedKeys: null };
    opt = { ...defaultOpt, ...opt };

    if (opt.mappedKeys) {
      params = this.renameKeys(params, opt.mappedKeys);
    }

    if (params.direction && params.order) {
      if (Array.isArray(params.order)) {
        params.sort = params.order
          .map((item) => {
            return `${item}:${params.direction}`;
          })
          .join("&sort=");
      } else {
        params.sort = `${params.order}:${params.direction}`;
      }
      delete params.order;
      delete params.direction;
    }

    if (params.filter && Array.isArray(params.filter)) {
      params.filter = params.filter
        .map((item) => {
          return (
            `${item.key}=` +
            (Array.isArray(item.value)
              ? `[${(<string[]>item.value).join(",")}]`
              : item.value)
          );
        })
        .join("&filter=");
    }

    if (params.exact && Array.isArray(params.exact)) {
      params.exact = params.exact
        .map((item) => {
          return `${item.key}=${item.value}`;
        })
        .join("&exact=");
    }

    if (params.search) {
      params.search = this.getSearchQuery(params.search);
    } else if (
      params.customSearch &&
      this.customSearchTermsCount(params.customSearch) > 0
    ) {
      params.search = this.getCustomSearchQuery(params.customSearch);

      delete params.customSearch;
    }

    return Utils.serializeQuery(params);
  }

  private getSearchQuery(searchInput) {
    if (searchInput.constructor.name == "Object") {
      return Object.keys(searchInput)
        .filter((itemKey) => searchInput[itemKey])
        .map((itemKey) => `${itemKey}=${searchInput[itemKey]}`)
        .join("&search=");
    } else if (searchInput.constructor.name == "Array") {
      return searchInput
        .filter((item) => !isNullOrEmpty(item.value?.trim()))
        .map((item) => {
          const searchedTerm = item.value?.trim();

          if (Array.isArray(item.key)) {
            const searchedQuery = item.key
              .map((keyNamed) => `${keyNamed}=${searchedTerm}`)
              .join("&search=");

            return searchedQuery;
          }

          return `${item.key}=${searchedTerm}`;
        })
        .join("&search=");
    }
  }

  private getCustomSearchQuery(
    customSearchInput: IQueryParams["customSearch"]
  ) {
    const searchTerms: string[] = this.splitAndCleanSpaces(
      customSearchInput.input
    );

    const customSearchedKeys = [...customSearchInput.keys];

    return searchTerms
      .map((searchVal) => {
        return customSearchedKeys
          .map((itemKey) => `${itemKey}=${searchVal}`)
          .join("&search=");
      })
      .join("&search=");
  }

  private customSearchTermsCount(
    customSearchInput: IQueryParams["customSearch"]
  ) {
    const searchTerms: string[] = this.splitAndCleanSpaces(
      customSearchInput.input
    );

    return searchTerms.length;
  }

  getValue(value, key = null, opt = null) {
    //case1 : key=default.O007, value = 'new'
    //case2 : key=default.O007, value = ['new','new_order']
    //case3 : key=default.O007, value = [{key:'default.O007',value:'new'},{key:'default.O007',value:'new_order'}]

    opt = opt || { filterType: null, fallback: true };

    if (value && Array.isArray(value)) {
      const firstVal = value[0];

      if (firstVal.hasOwnProperty("key") || firstVal.hasOwnProperty("value")) {
        value = value
          .map((item) => {
            return `${item.key}=${item.value}`;
          })
          .join(`&${opt.filterType}=`);
      } else {
        value = value
          .map((item) => {
            return `${key}=${item}`;
          })
          .join(`&${opt.filterType}=`);
      }

      return opt.filterType ? `${opt.filterType}=${value}` : value;
    } else {
      if (value) {
        return opt.filterType
          ? `${opt.filterType}=${key}=${value}`
          : `${key}=${value}`;
      } else {
        if (opt.fallback) {
          return `${key}=`;
        } else {
          return "";
        }
      }
    }
  }

  renameKeys(params: IQueryParams, mappedKeys): IQueryParams {
    Object.keys(mappedKeys).forEach((key) => {
      if (params.order && params.order === key) {
        params.order = mappedKeys[key];
      }
    });

    return params;
  }

  whereOrSearch(key: string[] | string, search: string, queryString = "") {
    let extractedKeys = Array.isArray(key) ? key : [key];
    let extractedSearchTerm = search?.trim();

    const searchedQuery = extractedKeys
      .map((keyNamed) => `${keyNamed}=${extractedSearchTerm}`)
      .join("&search=");

    if (queryString) {
      return `${queryString}&search=${searchedQuery}`;
    }

    return `search=${searchedQuery}`;
  }

  private splitAndCleanSpaces(inputString) {
    if (!inputString) {
      return [];
    }

    let parts = inputString.split(" ");

    let cleanedParts = parts.filter((part) => part !== "");

    return cleanedParts;
  }
}

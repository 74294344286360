import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { AuthComponent } from "./auth.component";
import { AuthService } from "../core/services";

@NgModule({
  imports: [FormsModule, CommonModule, RouterModule],
  declarations: [AuthComponent],
  providers: [AuthService],
})
export class AuthModule {}

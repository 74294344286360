import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  Type,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  NgModel,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";

@Component({ template: "" })
export class InputControlAbstract<T>
  implements ControlValueAccessor, Validator
{
  private _isMandatory = false;
  inputControl: NgControl | AbstractControl;
  inputModelRef: NgModel;

  @Input()
  cid: string = uuid();

  @Input()
  disabled: boolean;

  @Input()
  set value(value: T) {
    this._value = value;
    this.notifyValueChange();

    if (this.isMandatory) {
      this.onValidationChange();
    }
  }

  get value(): T {
    return this._value;
  }

  @Input()
  get isMandatory(): boolean | string {
    return this._isMandatory;
  }

  set isMandatory(value: boolean | string) {
    this._isMandatory =
      value != null && value !== false && `${value}` !== "false";
  }

  initializeInputControl(): void {
    this.inputControl = this.injector.get(NgControl).control;
  }

  initializeInputBindModel(): void {
    this.inputModelRef = this.injector.get(NgModel);
  }

  /**
   * Invoked when the model has been changed
   */
  onChange: (value: T) => {};
  /**
   * Invoked when the model has been touched
   */
  onTouched: () => {};

  protected _value: T;
  protected cdRef: ChangeDetectorRef;

  constructor(public injector: Injector) {
    this.cdRef = injector.get<ChangeDetectorRef>(
      ChangeDetectorRef as Type<ChangeDetectorRef>
    );
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValidationChange: any = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
    this.notifyValueChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.isMandatory ? Validators.required(control) : null;
  }
}

export function uuid(a?: any): string {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ("" + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

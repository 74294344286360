import { APP_CONFIG } from "src/app/config/app.config";
import { Inject, Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, Subject, throwError } from "rxjs";
import { tap, switchMap, catchError } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { CacheService } from "./cache.service";
import { environment } from "../../../environments/environment";
import { JwtService } from "./jwt.service";
import { IdleService } from "./idle.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  // defaults
  authService: AuthService;
  private refreshSubject: Subject<any> = new Subject<any>();
  private excludedAssetsUrl = ["/assets/i18n"];

  /**
   * Creates an instance of InterceptorService.
   * @param {ToastrService} toastr
   * @memberof InterceptorService
   */
  constructor(
    private injector: Injector,
    private jwtService: JwtService,
    private idleService: IdleService,
    @Inject(APP_CONFIG) private config
  ) {}

  /**
   * this is to intercept the http request
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof InterceptorService
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);

    const reqContentType = request.headers.get("Content-Type");
    const contentType = reqContentType ? reqContentType : "application/json";

    // set auth headers
    request = this.addAuthHeader(request);

    // creating a request instance
    request = request.clone({
      url: this.generateAPIEndpoint(environment, request.url),
      setHeaders: { "Content-Type": contentType },
    });

    if (request.url.endsWith("/refresh")) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap(
        (response) => this.handleResponse(response),
        (error) => this.handleError(request, error, next)
      ),
      catchError((error: HttpErrorResponse) => {
        if (
          this.isTokenExpiredError(error) ||
          this.authService.verifyRefreshToken() ||
          this.idleService.checkIdleTimeoutRemaining()
        ) {
          return this.authService.reinvokeTokenRequest().pipe(
            switchMap(() => {
              request = request.clone();

              return next.handle(request);
            }),
            catchError((error) => {
              return throwError(error);
            })
          );
        } else {
          this.authService.logout();
          this.authService.sessionExpiryAlert(error.error.details);
        }

        return throwError(error);
      })
    );
  }

  generateAPIEndpoint(environment, requestUrl: string) {
    const filteredRequestUrl = this.trimUrl(requestUrl);
    const foundAssetsUrl = this.excludedAssetsUrl.some((itemUrl) =>
      filteredRequestUrl.startsWith(this.trimUrl(itemUrl))
    );

    if (foundAssetsUrl) {
      return;
    }

    let url = environment.baseUrl + environment.urlSuffix + requestUrl;
    let routesExceptRegex = /(users\/me)|(token)/gi;
    if (routesExceptRegex.test(requestUrl)) {
      url = environment.baseUrl + requestUrl;
    }
    return url;
  }

  trimUrl(url: string) {
    return url.replace(/^[/\.]+|[/\.]+$/g, "");
  }

  /**
   * this is to handle response
   * @param {HttpRequest<any>} request
   * @param {HttpEvent<any>} res
   * @memberof InterceptorService
   */
  private handleResponse(res: HttpEvent<any>) {
    try {
      if (res instanceof HttpResponse) {
        res = res.clone({
          body: res.body ? res.body.result : null,
        });
        if (this.config.environment !== "production") {
          console.log("::Interceptor Response::", res);
        }
      }
      return res;
    } catch (error) {
      if (this.config.environment !== "production") {
        console.log("error in handleResponse: ", error);
      }
    }
  }

  /**
   * this is to handle error
   * @param {HttpRequest<any>} request
   * @param {*} event
   * @memberof InterceptorService
   */
  private handleError(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ) {
    if (error instanceof HttpErrorResponse) {
      if (this.config.environment !== "production") {
        console.log("::Interceptor Error::", error);
      }

      throw error;
    }
  }

  /**
   * represents add auth headers
   * to the request object
   * @private
   * @param {HttpRequest<any>} request
   * @returns {HttpRequest<any>}
   * @memberof InterceptorService
   */
  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    //const token = this.cacheService.get("token");
    const token = this.jwtService.getToken();
    /* const credentials = this.authService.getCredentials;
    let headers = {
      "X-Token": environment.xToken,
    }; */

    /* if (credentials) {
      headers["Authorization"] = "Basic " + credentials;
    } */
    if (token) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    return request;
  }

  private isTokenExpired() {
    try {
      this.refreshSubject.subscribe({
        complete: () => {
          this.refreshSubject = new Subject<any>();
        },
      });
      if (this.refreshSubject.observers.length === 1) {
        this.authService.getRefreshToken().subscribe(this.refreshSubject);
      }
      return this.refreshSubject;
    } catch (error) {
      console.log("error isTokenExpired: ", error);
    }
  }

  /**
   * represents check if error
   * is for expired token
   * @private
   * @param {HttpErrorResponse} error
   * @returns {boolean}
   * @memberof InterceptorService
   */
  private isTokenExpiredError(error: HttpErrorResponse): boolean {
    return error.status && error.status === 401;
  }
}

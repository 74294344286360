import { AbstractControl, Validators } from "@angular/forms";

export class BaseValidator extends Validators {
  static readonly argsBuiltinValidations = [
    "maxLength",
    "minLength",
    "min",
    "max",
    "email",
  ];
  static readonly lengthValidations = ["maxLength", "minLength"];

  static maxLength(length) {
    return (c: AbstractControl) => {
      const maxLengthCallback = Validators.maxLength(length);
      if (maxLengthCallback(c)) {
        return { maxLength: `Field must have ${length} characters` };
        /* return {
          maxLength: {
            msgKey: `FORM_ERRORS.FE010`,
            params: { length },
          },
        }; */
      }
      return null;
    };
  }

  static minLength(length) {
    return (c: AbstractControl) => {
      const minLengthCallback = Validators.minLength(length);
      if (minLengthCallback(c)) {
        return { minLength: `Field must have ${length} characters` };
        /* return {
          minLength: {
            msgKey: `FORM_ERRORS.FE011`,
            params: { length },
          },
        }; */
      }
      return null;
    };
  }

  static email(c: AbstractControl) {
    const emailCallback = Validators.email;
    if (emailCallback(c)) {
      return { email: `FORM_ERRORS.FE009` };
    }
    return null;
  }
}

import { IProject } from "src/app/commons/ITypes";
import { ICampaignProductInput } from "../ITypes";
import { ProductEditor } from "./ProductEditor";
import { ASSORTMENT_FIELD_KEY } from "../constants";

export class Project implements Omit<IProject, "products"> {
  title: string;
  start_date: string;
  end_date: string;
  publish_date: string;
  is_published: 0 | 1;
  products: Partial<ICampaignProductInput>[];
  productFields: string[] = [];

  constructor(project) {
    Object.assign(this, project);

    this.products = ProductEditor.toModel(project.products);
  }

  static create(project: IProject) {
    return new Project(project);
  }

  static toRequestEntity(project: IProject, products = null): IProject {
    const request: IProject = {
      title: project.title,
      start_date: project.start_date,
      end_date: project.end_date,
      publish_date: project.publish_date,
      is_published: project.is_published,
      products: products || project.products || [],
      productFields: (project.productFields || []).filter(
        (fieldKey) => [ASSORTMENT_FIELD_KEY].indexOf(fieldKey) === -1
      ),
    };

    return request;
  }
}

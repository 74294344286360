<div class="layout side-menu--open">
  <!-- navbar -->
  <sv-navbar></sv-navbar>

  <div class="wrapper wrapper--layout">
    <!-- content -->
    <sv-sidebar></sv-sidebar>

    <div id="content">
      <router-outlet></router-outlet>
      <sv-confirm-dialog></sv-confirm-dialog>
    </div>
  </div>
</div>

<div class="row page-error-container">
  <div class="col-md-10">
    <div class="forbidden">
      <div class="forbidden__header">
        <div class="icon-indicator">
          <span class="icon-holder">
            <i class="fa fa-exclamation-triangle"></i>
            <span class="icon-status">404</span>
          </span>
        </div>
        <h2>Oops! Page Not Found</h2>
      </div>
      <div class="forbidden__body">
        <p>
          We're sorry, the page you requested could not be found. Please go back
          to the homepage.
        </p>
      </div>
      <div class="forbidden__footer">
        <a [routerLink]="redirectLink" class="btn btn-primary btn-lg">
          Go to Home <i class="fa fa-chevron-right"></i>
        </a>
        <div class="company-logo">
          <img src="assets/logo-wide.png" alt="logo" />
        </div>
      </div>
    </div>
  </div>
</div>

import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GrantRoleType } from "src/app/core/ITypes";
import { AuthService } from "src/app/core/services";

@Directive({
  selector: "[svAccessPermission]",
})
export class AccessPermissionDirective {
  private destroyed$ = new Subject();
  private permissions: GrantRoleType[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService
  ) {}

  @Input()
  set svAccessPermission(permissions: GrantRoleType | GrantRoleType[]) {
    this.permissions =
      typeof permissions === "string" ? [permissions] : permissions || [];

    if (!permissions || permissions.length == 0) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }

    this.updateView();

    // Get notified each time authentication state changes.
    this.authService.authUser$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => this.updateView());
  }

  private updateView(): void {
    this.authService.validateRolePermission(this.permissions).then((result) => {
      this.viewContainerRef.clear();
      if (result) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

export const displayGroupFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        {
          key: "D001",
          type: "number",
          dataType: "intText",
          label: "Code",
          labelKey: "DISPLAY_GROUP.D000",
          placeholder: "Code",
          placeholderKey: "DISPLAY_GROUP.D000",
          castInput: false,
          maxLength: 6,
          validators: [
            { callback: "required" },
            {
              callback: "minCodeLength",
              requiredSchema: true,
            },
          ],
        },
        {
          key: "fi_name",
          type: "textfield",
          required: true,
          label: "Title in Finnish",
          labelKey: "DISPLAY_GROUP.D001",
          placeholder: "Title (Fi)",
          placeholderKey: "DISPLAY_GROUP.D008",
          prop: "name.fi",
        },
        {
          key: "en_name",
          type: "textfield",
          required: true,
          validate: false,
          label: "Title in English",
          labelKey: "DISPLAY_GROUP.D002",
          placeholder: "Title (En)",
          placeholderKey: "DISPLAY_GROUP.D009",
          prop: "name.en",
        },
        {
          key: "displaygroup_set",
          type: "dropdown",
          dataType: "int",
          protected: true,
          label: "Group Level",
          labelKey: "DISPLAY_GROUP.D003",
          hidden: false,
          data: {
            values: [],
          },
          validators: [{ callback: "required" }],
        },
        {
          key: "C004",
          type: "number",
          dataType: "intText",
          required: false,
          validate: false,
          label: "Product Group ID",
          labelKey: "DISPLAY_GROUP.D004",
          placeholder: "Product Group ID",
          placeholderKey: "DISPLAY_GROUP.D004"
        },
        {
          key: "active",
          type: "dropdown",
          required: false,
          validate: false,
          label: "Status",
          labelKey: "DISPLAY_GROUP.D005",
          placeholder: "Status",
          placeholderKey: "DISPLAY_GROUP.D005",
          default: 0,
          data: {
            values: [],
          },
          clearable: false,
        },
        {
          key: "order",
          type: "number",
          dataType: "int",
          label: "Order",
          labelKey: "DISPLAY_GROUP.D006",
          hidden: true,
        },
        {
          key: "role",
          type: "textfield",
          dataType: "int",
          label: "Role",
          labelKey: "DISPLAY_GROUP.D007",
          hidden: true,
          default: 1,
        },
      ],
    },
  },
};

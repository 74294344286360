import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Injectable()
export class DatepickerFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = "YYYY-MM-DD";

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      let mdt: any = moment(value, this.DT_FORMAT);
      return {
        month: Number(mdt.format("MM")),
        day: Number(mdt.format("DD")),
        year: Number(mdt.format("YYYY")),
      };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return "";
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return "";
    return mdt.format(this.DT_FORMAT);
  }
}

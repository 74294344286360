import { IProduct } from "src/app/commons/ITypes";

export enum OrderPanelFormType {
  ADDRESS = "deliveryAddress",
  SCHEDULE = "deliveryTime",
}

export interface IOrderEditMode {
  deliveryAddress: IOrderPanel<IDeliveryAddress>;
  deliveryTime: IOrderPanel<IDeliveryTime>;
}

export interface IOrderPanel<Type> {
  mode: boolean;
  root?: string;
  model: Type;
  loading: boolean;
  preserve: boolean;
  mappedTo: any;
  rootProp?: string;
  input?: any;
  req_excludes?: string[];
  req_excludes_if?: () => string[];
}

export interface IDeliveryAddress {
  default: {
    O030: string;
    O031: string;
    O032: string;
    O033: string;
    O034: string;
    O035: string;
    O036: string;
    O037: string;
  };
  shipping_firstname: string;
  shipping_lastname: string;
  shipping_email: string;
  shipping_phone: string;
  shipping_street: string;
  shipping_city: string;
  shipping_zip: string;
  shipping_country: string;
}

export interface IDeliveryTime {
  default: {
    O013: string;
    O014: string;
  };
  delivery_details: {
    fromtime: string;
    totime: string;
    starttime: string;
    endtime: string;
  } & ({ delivery_date: string } | { expected_delivery_date: string });
}

export interface IOrderState {
  type: OrderPanelMode;
  value: any;
}

export type OrderPanelMode = "deliveryAddress" | "deliveryTime";

export interface IOrderReclamationGroup {
  _id: number;
  reclamationID: string;
  invoiceID: string;
  date: string;
  title: string;
  products: IProduct[];
}

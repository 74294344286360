import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<any> {
  readonly DT_FORMAT = "YYYY-MM-DD";

  fromModel(value: string | null = "::00"): NgbDateStruct | null {
    //console.log("value", value);
    if (!value) {
      return null;
    }

    value = value.trim();
    let mdt: any = moment(value, this.DT_FORMAT);

    return {
      month: Number(mdt.format("MM")),
      day: Number(mdt.format("DD")),
      year: Number(mdt.format("YYYY")),
    };
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (!date) return null;
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return "";
    return mdt.format(this.DT_FORMAT);
  }
}

<ng-container *ngIf="key">{{ key | localeTrans : defaultOpt }}</ng-container>
<ng-container *ngIf="!key">{{ defaultText || "" }}</ng-container>

<div
  *ngIf="viewMode && debugMode && key"
  class="debugger-block"
  [class.d-inline-block]="modeInline"
>
  <span *ngIf="!extractKey">
    {{ key | json }}
  </span>
  <span *ngIf="extractKey && formatterView.key">
    {{ formatterView.key }}
  </span>
  <!-- <span>
    {{
      {
        key: key,
        text: (key | localeTrans : defaultOpt)
      } | json
    }}
  </span> -->
</div>

import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputControlAbstract } from "src/app/core/abstracts/input-control.abstract";

@Component({
  selector: "sv-textfield",
  templateUrl: "./textfield.component.html",
  styleUrls: ["./textfield.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextfieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextfieldComponent),
      multi: true,
    },
  ],
})
export class TextfieldComponent
  extends InputControlAbstract<unknown>
  implements OnInit
{
  @ViewChild("controlRef") controlRef: NgModel;

  @Input() placeholder;

  @Input() placeholderKey;

  @Input() label: string;

  @Input() labelKey: string;

  @Input() readonly: boolean = false;

  @Input() inputClass: string | string[] | { [klass: string]: any };

  @Input() containerClass: string | string[] | { [klass: string]: any };

  @Input() hasInputGroup: boolean = false;

  @Input() markRequired: boolean = false;

  @Input() name: string;

  @Output("change") change = new EventEmitter();

  hasInvalid: boolean = false;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.initializeInputBindModel();
  }

  handleChange() {
    this.change.emit(this.value);
  }

  onBlur() {
    if (!this.value) {
      this.hasInvalid = true;
    }
  }
}
